import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import "../brands/brands.css";
import swal from "sweetalert";
import { useMutation, useQuery } from "@apollo/client";
import { CreateBanner } from "../../../graphql/Mutations";
import { GET_ALLBRAND } from "../../../graphql/Query";

import AWS from "aws-sdk";
import uniqid from "uniqid";
import Resizer from "react-image-file-resizer";

const ID = "AKIA6GB4RFKTNZRHNUTV";
const SECRET = "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";
const BUCKET_NAME = "satyambucket";
const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

const AddBanner = () => {
  const [createMutation, { loading }] = useMutation(CreateBanner);
  const { data: BrandList } = useQuery(GET_ALLBRAND);

  const imgRef = useRef();

  const [bannertitle, setTitle] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [brandName, setBrandName] = useState("Brands");
  const [brandId, setBrandId] = useState("");
  const [location, setBannerLocation] = useState("");
  const [otherDetails, setotherDetails] = useState("");
  const [bannerStatus, setBannerStatus] = useState("Status");
  const [validated, setValidated] = useState(false);
  const [bannerUrl, setBannerUrl] = useState("");

  function clearFields() {
    setTitle("");
    setBannerImage("");
    setBrandName("Brands");
    setBrandId("");
    setBannerLocation("");
    setotherDetails("");
    setBannerStatus("Status");
    setBannerUrl("");

    imgRef.current.value = null;
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "jpg",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  async function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      try {
        let regex = new RegExp("[^.]+$");
        let extension = bannerImage.name.match(regex);

        const uniqId = uniqid();

        let uniqueFileName = uniqId + "." + extension[0];

        const image = bannerImage;
        const params = {
          Bucket: "satyambucket",
          Key: uniqueFileName,
          Body: image,
        };

        s3.upload(params, function (err, data) {
          if (err) {
            throw err;
          } else {
            createMutation({
              variables: {
                bannerInput: {
                  image: `${uniqueFileName}`,
                  bannerTitle: `${bannertitle}`,
                  bannerUrl: `${bannerUrl}`,
                  other: `${otherDetails}`,
                  location: `${location}`,
                  brandName: `${brandName}`,
                  brandId: `${brandId}`,
                  status: `${bannerStatus}`,
                },
              },
            })
              .then((data) => {
                swal({
                  title: "Success",
                  text: "Banner Added Successfully",
                  icon: "success",
                });
                clearFields();
                window.location.reload();
              })
              .catch((err) => alert(err));
          }
        });
      } catch (error) {
        console.log("Err", error);
      }
    }
  }

  return (
    <>
      <Container>
        <Row className="mx-4 ">
          <h2 className="page-title mb-3">Manage Banner</h2>
          <Col>
            <div className="box-shadow">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3 brands_form">
                  {/* Upload Banner Image */}
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Upload Banner Image{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      ref={imgRef}
                      type="file"
                      className="txtinpt"
                      placeholder="Image"
                      onChange={(e) => setBannerImage(e.target.files[0])}
                    />
                  </Form.Group>

                  {/* Banner Title */}
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Banner Title <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Banner Title"
                      value={bannertitle}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>

                  {/* Banner Location */}
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Banner Location{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Banner Location"
                      value={location}
                      onChange={(e) => setBannerLocation(e.target.value)}
                    />
                  </Form.Group>

                  {/* Others Details */}
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Others Details{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Others Details"
                      value={otherDetails}
                      onChange={(e) => setotherDetails(e.target.value)}
                    />
                  </Form.Group>

                  {/* Brand Selection */}
                  <Form.Group as={Col} md="3" className="pt-4">
                    <Form.Label>
                      Choose The Brand:{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic-brand"
                        size="sm"
                        className="rounded-pill w-100 mt-3 text-capitalize"
                      >
                        {brandName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {BrandList?.getBrandAll?.map(
                          ({ id, brandName }, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                setBrandId(id);
                                setBrandName(brandName);
                              }}
                            >
                              {brandName}
                            </Dropdown.Item>
                          )
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>

                  {/* Status Selection */}
                  {/* <Form.Group as={Col} md="3" className="pt-4">
                    <Form.Label>
                      Select Status:{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                  </Form.Group> */}
                  {/* <Form.Group as={Col} md="3">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic-status"
                        size="sm"
                        className="rounded-pill w-100 mt-3 text-capitalize"
                      >
                        {bannerStatus}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => setBannerStatus("Active")}
                        >
                          Active
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => setBannerStatus("Inactive")}
                        >
                          Inactive
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setBannerStatus("Empty")}>
                          Empty
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>

                  <Form.Group className="mb-3" as={Col} md="12">
                    <Form.Label>
                      Banner Url <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Banner Url"
                      value={bannerUrl}
                      onChange={(e) => setBannerUrl(e.target.value)}
                    />
                  </Form.Group>
                  </Form.Group> */}
                </Row>
                {loading ? (
                  <Spinner animation="border" className="mx-auto d-block" />
                ) : (
                  <Button
                    type="submit"
                    style={{ fontFamily: "dm sans" }}
                    className="loginBtnn mx-auto d-block mt-5"
                  >
                    Add
                  </Button>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddBanner;
