import React, { useState, useEffect } from "react";
import { HiCurrencyRupee } from "react-icons/hi2";
import "../componentCss/navbar.css";
import { BiMenuAltRight, BiSearch } from "react-icons/bi";
import { IoMdLogOut } from "react-icons/io";
import { AiOutlineClose, AiOutlineShoppingCart } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/logo2.jpg";
import { Image, DropdownButton, Dropdown } from "react-bootstrap";
import { useContext } from "react";
import { DataContext } from "../contextApi";
import { GET_USER_BY_TOKEN } from "../graphql/Query";
import { useQuery } from "@apollo/client";
import AuthContext from "../context/AuthProvider";
import "../componentCss/navbar.css";
import styled from "styled-components";
import StoreLocatorModal from "./StoreLocatorModal";
import { FaLessThan } from "react-icons/fa";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
`;

const LogoImage = styled.img`
  width: 50px; /* Adjust the size as needed */
  height: auto;
  margin-right: 0.5rem; /* Adjust spacing between image and text */
`;

const LogoText = styled.span`
  font-size: 1.5rem; /* Adjust font size */
  font-weight: bold; /* Adjust font weight */
  color: #333; /* Adjust color to match your design */
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none; /* Remove underline */
`;

const Navbar = () => {
  const { token, setUserId, setToken } = useContext(AuthContext);

  const { data: UserData } = useQuery(GET_USER_BY_TOKEN, {
    variables: {
      token: `${token}`,
    },
    onCompleted: () =>
      setUsername(UserData?.getUserByToken?.firstName || false),
  });

  const [userName, setUsername] = useState(
    UserData?.getUserByToken?.firstName || false
  );

  useEffect(
    () => setUsername(UserData?.getUserByToken?.firstName || false),
    [UserData]
  );

  const [menuOpen, setMenuOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [locationKeyword, setLocationKeyword] = useState("");
  const [showLocatorModal, setShowLocatorModal] = useState(false);
  const { cartValue } = useContext(DataContext);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const navigate = useNavigate();

  const redirectToLogin = () => {
    navigate("/login");
  };
  const redirectToSellWithUs = () => {
    navigate("/vendorSignup");
  };
  const redirectToVendorLogin = () => {
    navigate("/vendorLogin");
  };

  const redirectToCart = () => {
    navigate("/cart");
  };

  function handleLogout() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userId");
    sessionStorage.removeItem("userSessionToken");
    setUserId(false);
    setToken(false);
    navigate("/login");
  }

  const handleLocatorShow = () => {
    if (!locationKeyword.length) return;
    setShowLocatorModal(true);
  };

  return (
    <>
      {showLocatorModal ? (
        <StoreLocatorModal
          keyword={locationKeyword}
          show={showLocatorModal}
          setShow={setShowLocatorModal}
        />
      ) : null}
      <nav
        className={`${menuOpen ? "shadow menuopen" : "shadow"}`}
        style={{ zIndex: "100" }}
      >
        <Wrapper>
          <StyledLink to="/">
            <LogoImage className="logo_img" src={Logo} alt="Logo" />
            <LogoText>Ekagam</LogoText>
          </StyledLink>
        </Wrapper>
        <div
          className={`${
            menuOpen ? "nav_part2" : "nav_part2 d-lg-flex d-none "
          }`}
        >
          <div className="nav_search_bar_part">
            <div className="nav_search">
              <BiSearch style={{ color: "gray" }} className="me-1 mt-1" />
              {/* search text */}
              <input
                type="text"
                placeholder="Search coupons..."
                style={{ fontFamily: "dm sans" }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <button
              style={{ fontFamily: "dm sans" }}
              onClick={() =>
                searchText !== ""
                  ? navigate(`/search-coupons/${searchText} `)
                  : null
              }
            >
              Search
            </button>
          </div>
        </div>
        <div
          className={`${
            menuOpen ? "nav_part2" : "nav_part2 d-lg-flex d-none "
          }`}
        >
          <div className="nav_search_bar_part">
            <div className="nav_search">
              <BiSearch style={{ color: "gray" }} className="me-1 mt-1" />
              {/* search text */}
              <input
                type="text"
                placeholder="Search Stores.."
                style={{ fontFamily: "dm sans" }}
                value={locationKeyword}
                onChange={(e) => setLocationKeyword(e.target.value)}
              />
            </div>
            <button
              style={{ fontFamily: "dm sans" }}
              onClick={handleLocatorShow}
            >
              Search
            </button>
          </div>
        </div>
        <div className="nav_part3">
          {token ? (
            <>
              <div className="d-flex align-items-center ">
                <div className="nav_icons_part">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={redirectToCart}
                    className="icons"
                  >
                    <AiOutlineShoppingCart className="fs-4" />
                    <span className="iNum" style={{ fontFamily: "dm sans" }}>
                      {cartValue}
                    </span>
                  </div>
                </div>
                <Link
                  to="/user/userCoupons"
                  className="text-decoration-none text-black"
                >
                  <h6 className="ms-4" style={{ fontFamily: "dm sans" }}>
                    Dashboard
                  </h6>
                </Link>
              </div>
              <div
                className="ms-3 px-3 py-1 border rounded-5"
                onClick={handleLogout}
              >
                <span style={{ fontFamily: "dm sans" }}>{userName}</span>
                <span className="ms-2 text-danger">
                  <IoMdLogOut className="fs-4" />
                </span>
              </div>
            </>
          ) : (
            <DropdownButton
              id="dropdown-basic-button"
              title="Login"
              style={{ fontFamily: "dm sans", fontWeight: "bold" }}
            >
              <Link
                to="/login"
                style={{ fontFamily: "dm sans", textDecoration: "none" }}
              >
                <Dropdown.Item onClick={redirectToLogin}>
                  User Login
                </Dropdown.Item>
              </Link>
              <Link
                to="/vendorLogin"
                style={{ fontFamily: "dm sans", textDecoration: "none" }}
              >
                <Dropdown.Item onClick={redirectToVendorLogin}>
                  Vendor Login
                </Dropdown.Item>
              </Link>
            </DropdownButton>
          )}
        </div>

        {/* for vendor */}
        {/* <div className="nav_part4">
          {
            token ? (
            <>
              <div className="d-flex align-items-center ">
                <div className="nav_icons_part">
                  <div style={{ cursor: "pointer" }} onClick={redirectToCart} className="icons">
                    <AiOutlineShoppingCart className="fs-4" />
                    <span className="iNum" style={{ fontFamily: "dm sans" }}>
                      {cartValue}
                    </span>
                  </div>
                </div>
                <Link to="/user/userCoupons" className="text-decoration-none text-black">
                  <h6 className="ms-4" style={{ fontFamily: "dm sans" }}>
                    Dashboard
                  </h6>
                </Link>
              </div>
              <div className="ms-3 px-3 py-1 border rounded-5" onClick={handleLogout}>
                <span style={{ fontFamily: "dm sans" }}>{userName}</span>
                <span className="ms-2 text-danger">
                  <IoMdLogOut className="fs-4" />
                </span>
              </div>
            </>
          ) : (
            <button onClick={redirectToSellWithUs} className="login_btn ms-lg-3 d-flex align-items-center">
              <HiCurrencyRupee className="me-1 fs-5" />Sell with us
            </button>
          )}
        </div> */}
        {/* for phone */}
        <div className="menu_icon" onClick={toggleMenu}>
          {menuOpen ? <AiOutlineClose /> : <BiMenuAltRight />}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
