import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Dropdown } from "react-bootstrap";
import "../brands/brands.css";
import swal from "sweetalert";
import { useMutation } from "@apollo/client";
import { CreateTreadingDeal } from "../../../graphql/Mutations";
// import { GET_ALLBRAND } from "../../../graphql/Query";

import AWS from "aws-sdk";
import uniqid from "uniqid";
import Resizer from "react-image-file-resizer";
import { useRef } from "react";

const ID = "AKIA6GB4RFKTNZRHNUTV";
const SECRET = "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";
const BUCKET_NAME = "satyambucket";
const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

export default function AddTrandingDeals() {
  const [createMutation] = useMutation(CreateTreadingDeal);
  // const { data: BrandList } = useQuery(GET_ALLBRAND);

  const imgRef = useRef();

  const [title, setTitle] = useState("");
  const [Image, setImage] = useState("");
  const [dealUrl, setDealUrl] = useState("");
  //const [brandName, setBrandName] = useState("Brands");
  // const [brandId, setBrandId] = useState("");
  const [otherDetails, setotherDetails] = useState("");
  const [bannerStatus, setBannerStatus] = useState("Status");
  const [validated, setValidated] = useState(false);

  function clearFields() {
    setTitle("");
    setImage("");
    setDealUrl("");
    setotherDetails("");
    setBannerStatus("Status");

    imgRef.current.value = null;
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "jpg",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  async function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      try {
        let regex = new RegExp("[^.]+$");
        let extension = Image.name.match(regex);

        const uniqId = uniqid();

        let uniqueFileName = uniqId + "." + extension[0];

        const image = await resizeFile(Image);
        const params = {
          Bucket: "satyambucket",
          Key: uniqueFileName,
          Body: image,
        };

        s3.upload(params, function (err, data) {
          if (err) {
            throw err;
          } else {
            createMutation({
              variables: {
                trandingDealInput: {
                  image: `${uniqueFileName}`,
                  dealName: `${title}`,
                  description: `${otherDetails}`,
                  dealUrl: `${dealUrl}`,
                  status: `${bannerStatus}`,
                },
              },
            })
              .then((data) => {
                swal({
                  title: "Success",
                  text: "Coupon Added Successfully",
                  icon: "success",
                });
                clearFields();
                window.location.reload();
              })
              .catch((err) => alert(err));
          }
        });
      } catch (error) {
        console.log("Err", error);
      }
    }
  }

  return (
    <>
      <Container>
        <Row className="mx-4 ">
          <h2 className="page-title mb-3">Manage Trending Deals</h2>
          <Col>
            <div className="box-shadow">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3 brands_form">
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>Upload Trending Deals Image <span className="required-asterisk">*</span></Form.Label>
                    <Form.Control
                      required
                      ref={imgRef}
                      type="file"
                      className="txtinpt"
                      placeholder="Image"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>Trending Deals Title <span className="required-asterisk">*</span></Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Trending Deals Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="12">
                    <Form.Label>Trending Deals Brand URL <span className="required-asterisk">*</span></Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Trending Deals Brand URL"
                      value={dealUrl}
                      onChange={(e) => setDealUrl(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Add Description <span className="required-asterisk">*</span></Form.Label>

                    <Form.Control
                      required
                      as="textarea"
                      placeholder="Add Description here"
                      style={{ height: "100px" }}
                      value={otherDetails}
                      onChange={(e) => setotherDetails(e.target.value)}
                    />
                  </Form.Group>

                  {/* <Form.Group
                    as={Col}
                    md="3"
                    controlId="validationCustom02"
                    className="pt-4"
                  >
                    <Form.Label>Choose The Brand :</Form.Label>
                  </Form.Group>
                 <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        size="sm"
                        className="rounded-pill w-100 mt-3  text-capitalize"
                      >
                        {brandName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {BrandList?.getBrandAll?.map(
                          ({ id, brandName }, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                setBrandId(id);
                                setBrandName(brandName);
                              }}
                            >
                              {brandName}
                            </Dropdown.Item>
                          )
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group> */}
                  <Form.Group
                    as={Col}
                    md="3"
                    controlId="validationCustom02"
                    className="pt-4"
                  >
                    <Form.Label>Select Status : <span className="required-asterisk">*</span></Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        size="sm"
                        className="rounded-pill w-100 mt-3 text-capitalize"
                      >
                        {bannerStatus}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => setBannerStatus("Active")}
                        >
                          Active
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => setBannerStatus("Inactive")}
                        >
                          Inactive
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setBannerStatus("Empty")}>
                          {" "}
                          Empty
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Button type="submit" className="d-block mx-auto  mt-5">
                  Add Trending Deals
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
