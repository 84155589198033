import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner,
  Image,
  Dropdown,
  Modal,
  FloatingLabel,
} from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import "../brands/brands.css";
import swal from "sweetalert";

import { CREATE_CATEGORY } from "../../../graphql/Mutations";
import { useMutation } from "@apollo/client";

import AWS from "aws-sdk";
import uniqid from "uniqid";
const ID = "AKIA6GB4RFKTNZRHNUTV";
const SECRET = "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";
//const BUCKET_NAME = "satyambucket";
const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

export default function AddCategory() {
  const [createCategory] = useMutation(CREATE_CATEGORY);

  const [validated, setValidated] = useState(false);
  const [categoryBannerImage, setCategoryBannerImage] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [status, setStatus] = useState("Status");

  function clearFields() {
    setCategoryName("");
    setStatus("Status");
  }

  async function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      try {
        let regex = new RegExp("[^.]+$");
        let extension = categoryBannerImage.name.match(regex);

        const uniqId = uniqid();

        let uniqueFileName = uniqId + "." + extension[0];

        const image = categoryBannerImage;

        const params = {
          Bucket: "satyambucket",
          Key: uniqueFileName,
          Body: image,
        };

        s3.upload(params, function (err, data) {
          if (err) {
            throw err;
          } else {
            createCategory({
              variables: {
                categoryInput: {
                  categoryBannerImage: `${uniqueFileName}`,
                  categoryName: `${categoryName}`,
                  status: `${status}`,
                },
              },
            })
              .then((data) => {
                swal({
                  title: "Success",
                  text: "Category Added Successfully",
                  icon: "success",
                });
                clearFields();
                window.location.reload();
              })
              .catch((err) => alert(err));
          }
        });
      } catch (error) {
        console.log("Err", error);
      }
    }
  }

  return (
    <>
      <Container>
        <Row className="mx-4 ">
          <h2 className="page-title mb-3">Add Category </h2>
          <Col>
            <div className="box-shadow">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3 brands_form">
                  <Form.Group className="mb-3" as={Col} md="12">
                    <Form.Label>
                      Category Image Banner{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="file"
                      placeholder="Category Image Banner"
                      onChange={(e) =>
                        setCategoryBannerImage(e.target.files[0])
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Category Name <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Category Name"
                      value={categoryName}
                      onChange={(e) => setCategoryName(e.target.value)}
                    />
                  </Form.Group>

                  {/* <Form.Group
                    required
                    as={Col}
                    md="3"
                    controlId="validationCustom02"
                    className="pt-4"
                  >
                    <Form.Label>
                      Select Status :{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        size="sm"
                        className="rounded-pill w-100 mt-3 text-capitalize"
                      >
                        {status}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setStatus("Active")}>
                          Active
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setStatus("Inactive")}>
                          Inactive
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setStatus("Pending")}>
                          Pending
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group> */}
                </Row>
                <Button type="submit" className="d-block mx-auto  mt-5">
                  Add Category
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
