import { gql } from "@apollo/client";

export const INCREASE_POOL_POINTS = gql`
  mutation Mutation($points: Int) {
    increasePoolPoints(Points: $points) {
      points
      lastUpdate
      status
      freezed
    }
  }
`;
export const DECREASE_POOL_POINTS = gql`
  mutation DecreasePoolPoints($points: Int) {
    decreasePoolPoints(Points: $points) {
      points
      lastUpdate
      status
      freezed
    }
  }
`;

/* <----------------------------------------- Admin login Section Start -----------------------------------------------------------> */

export const ADMIN_SIGNIN = gql`
  mutation Mutation($adminSigninInput: adminSigninInput) {
    adminSignin(AdminSigninInput: $adminSigninInput) {
      adminId
      adminUserName
      adminToken
    }
  }
`;

/* <----------------------------------------- Admin login Section Start -----------------------------------------------------------> */
/* <----------------------------------------- user Section Start -----------------------------------------------------------> */

export const USER_SIGN_UP = gql`
  mutation Mutation($userInput: userInput) {
    createUser(UserInput: $userInput) {
      id
      firstName
      lastName
      email
      contact
      image
      points {
        loginPoints
        referralPoints
        couponPoints
        totalPoints
      }
      earning
      refferalLevel
      referralId
      createdDateTime
      status
    }
  }
`;

export const USER_SIGN_IN = gql`
  mutation Mutation($userSigninInput: userSigninInput) {
    userSignIn(UserSigninInput: $userSigninInput) {
      userId
      userEmail
      userToken
      points {
        loginPoints
        referralPoints
        couponPoints
        totalPoints
      }
    }
  }
`;

/* <----------------------------------------- user Section Start -----------------------------------------------------------> */

export const CREATE_CATEGORY = gql`
  mutation Mutation($categoryInput: categoryInput) {
    createCategory(CategoryInput: $categoryInput) {
      categoryBannerImage
      categoryName
      createdDateTime
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation Mutation($categoryUpdateInput: categoryUpdateInput) {
    updateCategory(CategoryUpdateInput: $categoryUpdateInput) {
      id
      categoryBannerImage
      categoryName
      createdDateTime
      status
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation Mutation($categoryId: ID) {
    delteCategory(CategoryId: $categoryId) {
      id
      categoryName
      createdDateTime
      status
    }
  }
`;

export const CREATE_BRAND = gql`
  mutation Mutation($brandInput: brandInput) {
    createBrand(BrandInput: $brandInput) {
      id
      category
      categoryId
      brandName
      brandTitle
      brandUrl
      image
      description
      instructions
      redeemInstructions
      createdDateTime
      home
      saving
      status
    }
  }
`;
export const UPDATE_BRAND = gql`
  mutation Mutation($brandUpdateInput: brandUpdateInput) {
    updateBrand(BrandUpdateInput: $brandUpdateInput) {
      brandName
    }
  }
`;
export const DELETE_BRAND = gql`
  mutation Mutation($brandId: ID) {
    deleteBrand(BrandId: $brandId) {
      brandName
    }
  }
`;

/* <----------------------------------------- Coupon Section Start -----------------------------------------------------------> */
export const CreateCoupon = gql`
  mutation CreateCoupon($couponInput: couponInput) {
    createCoupon(CouponInput: $couponInput) {
      id
      title
      couponId
      brandName
      brandId
      storeName
      couponCode
      expirationDateTime
      termsAndConditions
      image
      amount
      quantity
      createdDateTime
      home
      status
    }
  }
`;
export const UPDATE_COUPON = gql`
  mutation Mutation($couponUpdateInput: couponUpdateInput) {
    updateCoupon(CouponUpdateInput: $couponUpdateInput) {
      id
      title
      couponId
      brandName
      brandId
      storeName
      couponCode
      expirationDateTime
      termsAndConditions
      image
      amount
      quantity
      createdDateTime
      home
      status
    }
  }
`;
export const DeleteCoupon = gql`
  mutation DeleteCoupon($couponId: ID) {
    deleteCoupon(CouponId: $couponId) {
      couponId
    }
  }
`;

/* <----------------------------------------- Coupon Section End -----------------------------------------------------------> */

/* <----------------------------------------- Banner Section Start -----------------------------------------------------------> */
export const CreateBanner = gql`
  mutation CreateBanner($bannerInput: bannerInput) {
    createBanner(BannerInput: $bannerInput) {
      id
      image
      bannerTitle
      bannerUrl
      other
      location
      brandName
      brandId
      createdDateTime
      status
    }
  }
`;
export const UpdateBanner = gql`
  mutation UpdateBanner($bannerUpdateInput: bannerUpdateInput) {
    updateBanner(BannerUpdateInput: $bannerUpdateInput) {
      id
      image
      bannerTitle
      bannerUrl
      other
      location
      brandName
      brandId
      createdDateTime
      status
    }
  }
`;
export const DeleteBanner = gql`
  mutation DeleteBanner($bannerId: ID) {
    deleteBanner(BannerId: $bannerId) {
      brandName
    }
  }
`;
/* <----------------------------------------- Banner Section End -----------------------------------------------------------> */

/* <----------------------------------------- Trending-deals Section Start -----------------------------------------------------------> */
export const CreateTreadingDeal = gql`
  mutation Mutation($trandingDealInput: trandingDealInput) {
    createTrendingDeal(TrandingDealInput: $trandingDealInput) {
      id
      dealName
      image
      description
      dealUrl
      createdDateTime
      status
    }
  }
`;
export const UPDATE_TRANDINGDEAL = gql`
  mutation Mutation($trandingDealUpdateInput: trandingDealUpdateInput) {
    updateTrendingDeal(TrandingDealUpdateInput: $trandingDealUpdateInput) {
      id
      dealName
      image
      description
      brandName
      brandId
      createdDateTime
      status
    }
  }
`;
export const DeleteTreandingDeal = gql`
  mutation DeleteTrendingDeal($trandingDealId: ID) {
    deleteTrendingDeal(TrandingDealId: $trandingDealId) {
      dealName
    }
  }
`;

/* <----------------------------------------- Trending-deals Section End -----------------------------------------------------------> */

/* <----------------------------------------- User Section Start -----------------------------------------------------------> */
export const DeleteUser = gql`
  mutation DeleteUser($userId: ID) {
    deleteUser(userId: $userId) {
      firstName
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdateUserPassword(
    $userPasswordUpdateInput: userPasswordUpdateInput
  ) {
    updateUserPassword(UserPasswordUpdateInput: $userPasswordUpdateInput)
  }
`;
/* <----------------------------------------- user Section End -----------------------------------------------------------> */
/* <----------------------------------------- order Start -----------------------------------------------------------> */

export const CREATE_ORDER = gql`
  mutation Mutation($orderInput: orderInput) {
    createOrder(OrderInput: $orderInput) {
      id
      userId
      sessionID
      orderedDateTime
      status
    }
  }
`;
/* <----------------------------------------- order End -----------------------------------------------------------> */

export const UPDATE_CATEGORY_STATUS = gql`
  mutation Mutation($categoryId: ID, $status: String) {
    updateCategoryStatus(categoryId: $categoryId, status: $status) {
      id
    }
  }
`;
export const UPDATE_BRAND_STATUS = gql`
  mutation UpdateBrandStatus($brandId: ID, $status: String) {
    updateBrandStatus(brandId: $brandId, status: $status) {
      id
    }
  }
`;
export const UPDATE_COUPON_STATUS = gql`
  mutation UpdateCouponStatus($couponId: ID, $status: String) {
    updateCouponStatus(couponId: $couponId, status: $status) {
      id
    }
  }
`;

export const CREATE_VENDOR = gql`
  mutation Mutation($vendorInput: vendorInput) {
    createVendor(VendorInput: $vendorInput) {
      vendorName
      vendorNumber
      accountName
      bankName
      bankBranch
      accountNumber
      ifsc
      createdDateTime
      status
    }
  }
`;

/* <----------------------------------------- subscriber start -----------------------------------------------------------> */
export const CREATE_SUBSCRIBER = gql`
  mutation Mutation($subscriberInput: subscriberInput) {
    createSubscriber(SubscriberInput: $subscriberInput) {
      email
    }
  }
`;
export const UPDATE_SUBSCRIBER = gql`
  mutation Mutation($subscriberUpdateInput: subscriberUpdateInput) {
    updateSubscriber(SubscriberUpdateInput: $subscriberUpdateInput) {
      id
      email
      createdDateTime
      status
    }
  }
`;
export const DELETE_SUBSCRIBER = gql`
  mutation Mutation($subId: ID) {
    deleteSubscriber(SubId: $subId) {
      email
    }
  }
`;

export const ADD_VENDOR_COUPON = gql`
  mutation CreateCouponForVendor($couponInput: couponInput) {
    createCouponForVendor(CouponInput: $couponInput) {
      id
      title
      couponId
      brandName
      categoryName
      brandId
      categoryId
      vendorId
      storeName
      couponCode
      expirationDateTime
      termsAndConditions
      image
      amount
      commission
      quantity
      discount
      saving
      youPaying
      createdDateTime
      home
      status
    }
  }
`;

/* <----------------------------------------- subscriber End -----------------------------------------------------------> */

export const ADD_VENDOR_MAIN = gql`
  mutation Mutation($vendorMainInput: vendorMainInput) {
    createVendorMain(VendorMainInput: $vendorMainInput) {
      id
    }
  }
`;
export const VENDOR_MAIN_LOGIN = gql`
  mutation Mutation($vendorMainInputInput: vendorMainInputInput) {
    vendorMainSignin(VendorMainInputInput: $vendorMainInputInput) {
      vendorId
      vendorMainEmail
      vendorMainToken
    }
  }
`;

export const COUPON_UPDATE_FOR_ADMIN = gql`
  mutation UpdateCouponForAdmin(
    $updateCouponForAdminInput: updateCouponForAdminInput
  ) {
    updateCouponForAdmin(
      UpdateCouponForAdminInput: $updateCouponForAdminInput
    ) {
      id
    }
  }
`;

export const VENDOR_STATUS_UPDATE = gql`
  mutation Mutation($vendorId: ID) {
    updateVendorMainStatus(VendorId: $vendorId)
  }
`;

export const VENDOR_UPDATE = gql`
  mutation UpdateVendor($vendorMainUpdateInput: vendorMainUpdateInput) {
    updateVendor(VendorMainUpdateInput: $vendorMainUpdateInput) {
      id
    }
  }
`;

//---------------------------redeem start ---------------------------------------

export const UPDATE_REDEEM_VALUE = gql`
  mutation Mutation($redeemValue: Int) {
    updateRedeemValue(RedeemValue: $redeemValue)
  }
`;

export const USER_REDEEM = gql`
  mutation Mutation($token: String) {
    userRedeem(Token: $token)
  }
`;

//---------------------------redeem end ---------------------------------------
//--------------------------otp-start------------------------------------------
export const GET_OTP = gql`
  mutation GetOtp($email: String) {
    getOtp(Email: $email)
  }
`;
export const RESET_PASSWORD = gql`
  mutation Mutation($forgetPasswordInput: forgetPasswordInput) {
    forgetPassword(ForgetPasswordInput: $forgetPasswordInput)
  }
`;

export const GET_VERIFICATION_OTP = gql`
  mutation Mutation($getVerifyOtpInput: getVerifyOtpInput) {
    getVerificationOtp(GetVerifyOtpInput: $getVerifyOtpInput)
  }
`;

export const VERIFY_EMAIL_OTP = gql`
  mutation VerifyEmailOtp($verifyEmailOtpInput: verifyEmailOtpInput) {
    verifyEmailOtp(VerifyEmailOtpInput: $verifyEmailOtpInput)
  }
`;
//--------------------------otp-end------------------------------------------
