import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [userId, setUserId] = useState(
    localStorage.getItem("userId") ||
      sessionStorage.getItem("userSessionUserId") ||
      false
  );
  const [token, setToken] = useState(
    localStorage.getItem("userToken") ||
      sessionStorage.getItem("userSessionToken") ||
      false
  );

  const [refUserId, setRefUserId] = useState(null);
  const [refCouponId, setRefCouponId] = useState(null);

  const provider = {
    userId,
    setUserId,
    token,
    setToken,
    refUserId,
    setRefUserId,
    refCouponId,
    setRefCouponId,
  };

  return (
    <AuthContext.Provider value={provider}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
