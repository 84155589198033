import React, { useState } from "react";
import { Button, Form, Modal, Row, Col, Dropdown } from "react-bootstrap";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALLBRAND, GET_CATEGORY } from "../../../graphql/Query";
import { UpdateBanner } from "../../../graphql/Mutations";
import sweetAlert from "sweetalert";

import AWS from "aws-sdk";
import uniqid from "uniqid";
const ID = "AKIA6GB4RFKTNZRHNUTV";
const SECRET = "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";
//const BUCKET_NAME = "satyambucket";
const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

const BannerEdit = ({ show, setShow, data }) => {
  const { data: BrandList } = useQuery(GET_ALLBRAND);
  const [updateBanner] = useMutation(UpdateBanner);
  const {
    id,
    image,
    bannerTitle,
    bannerUrl,
    other,
    location,
    brandName,
    brandId,
    createdDateTime,
    status,
  } = data;
  console.log(id);
  const [bannertitleEdit, setTitle] = useState(bannerTitle);
  const [bannerUrlEdit, setBannerUrlEdit] = useState(bannerUrl);
  const [bannerImage, setBannerImage] = useState(image);
  const [brandNameEdit, setBrandName] = useState(brandName);
  const [brandIdEdit, setBrandId] = useState(brandId);
  const [locationEdit, setBannerLocation] = useState(location);
  const [otherDetails, setotherDetails] = useState(other);
  const [bannerStatus, setBannerStatus] = useState(status);
  const [validated, setValidated] = useState(false);
  const [isUploaded, setIsuploaded] = useState(true);

  function handleImageChange(file) {
    setIsuploaded(false);
    handleS3Upload(file)
      .then(() => {
        setIsuploaded(true);
      })
      .catch(() => {
        setIsuploaded(false);
      });
  }

  async function handleS3Upload(file) {
    if (!file) {
      return false;
    } else {
      try {
        let regex = new RegExp("[^.]+$");
        let extension = file.name.match(regex);

        const uniqId = uniqid();

        let uniqueFileName = uniqId + "." + extension[0];

        const image = file;

        const params = {
          Bucket: "satyambucket",
          Key: uniqueFileName,
          Body: image,
        };
        s3.upload(params, function (err, data) {
          if (!err) {
            setBannerImage(uniqueFileName);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      updateBanner({
        variables: {
          bannerUpdateInput: {
            id: `${id}`,
            image: `${bannerImage}`,
            bannerTitle: `${bannertitleEdit}`,
            bannerUrl: `${bannerUrlEdit}`,
            other: `${otherDetails}`,
            location: `${locationEdit}`,
            brandName: `${brandNameEdit}`,
            brandId: `${brandIdEdit}`,
            status: `${bannerStatus}`,
          },
        },
      })
        .then((data) => {
          sweetAlert({
            title: "Success",
            text: "Banner Updated Successfully",
            icon: "success",
          });
          setShow(false);
          window.location.reload();
        })
        .catch((err) => alert(err));
    }
  }

  return (
    <div>
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <div className="box-shadow">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3 brands_form">
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Upload Banner Image
                      {isUploaded ? null : <span>Loading...</span>}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      className="txtinpt"
                      placeholder="Image"
                      onChange={(e) => handleImageChange(e.target.files[0])}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>Banner Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Banner Title"
                      value={bannertitleEdit}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>Banner Location</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Banner Location"
                      value={locationEdit}
                      onChange={(e) => setBannerLocation(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>Others Details</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Others Details"
                      value={otherDetails}
                      onChange={(e) => setotherDetails(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="3"
                    controlId="validationCustom02"
                    className="pt-4"
                  >
                    <Form.Label>Choose The Brand :</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        size="sm"
                        className="rounded-pill w-100 mt-3  text-capitalize"
                      >
                        {brandName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {BrandList?.getBrandAll?.map(
                          ({ id, brandName }, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                setBrandId(id);
                                setBrandName(brandName);
                              }}
                            >
                              {brandNameEdit}
                            </Dropdown.Item>
                          )
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="3"
                    controlId="validationCustom02"
                    className="pt-4"
                  >
                    <Form.Label>Select Status :</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        size="sm"
                        className="rounded-pill w-100 mt-3 text-capitalize"
                      >
                        {bannerStatus}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => setBannerStatus("Active")}
                        >
                          Active
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => setBannerStatus("Inactive")}
                        >
                          Inactive
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setBannerStatus("Empty")}>
                          {" "}
                          Empty
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="12">
                    <Form.Label>
                      Banner Url <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Banner Url"
                      value={bannerUrlEdit}
                      onChange={(e) => setBannerUrlEdit(e.target.value)}
                    />
                  </Form.Group>
                </Row>
                <Button type="submit" className="d-block mx-auto  mt-5">
                  Add Banner
                </Button>
              </Form>
            </div>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BannerEdit;
