import React, { useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Dropdown,
  Modal,
} from "react-bootstrap";
import "../brands/brands.css";
import swal from "sweetalert";
import Resizer from "react-image-file-resizer";
import { useMutation, useQuery } from "@apollo/client";
import { COUPON_UPDATE_FOR_ADMIN } from "../../../graphql/Mutations";
import { GET_ALLBRAND } from "../../../graphql/Query";
import { useLocation, useNavigate } from "react-router-dom";

import AWS from "aws-sdk";
import uniqid from "uniqid";
const ID = "AKIA6GB4RFKTNZRHNUTV";
const SECRET = "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";
const BUCKET_NAME = "satyambucket";
const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

export default function AddCoupons() {
  const location = useLocation();
  const navigation = useNavigate();
  const [updateCouponForAdmin] = useMutation(COUPON_UPDATE_FOR_ADMIN);
  const { data: BrandList } = useQuery(GET_ALLBRAND);

  const imgRef = useRef();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [poitnsValidated, setPointsValidated] = useState(false);

  const [couponMongoId, setCouponMongoId] = useState(location.state.id);
  const [title, setTitle] = useState(location.state.title);
  const [brandName, setBrandName] = useState(location.state.brandName);
  const [brandId, setBrandId] = useState(location.state.brandId);
  const [categoryName, setCategoryName] = useState(location.state.categoryName);
  const [categoryId, setCategoryId] = useState(location.state.categoryId);
  const [vendorId, setVendorId] = useState(location.state.categoryId);
  const [storeName, setStoreName] = useState(location.state.storeName);
  const [couponCode, setCouponCode] = useState(location.state.couponCode);
  const [expirationDateTime, setExpirationDateTime] = useState(
    location.state.expirationDateTime
  );
  const [termsAndConditions, setTermsAndConditions] = useState(
    location.state.termsAndConditions
  );
  const [couponImage, setCouponImage] = useState(location.state.image);
  const [couponAmount, setCouponAmount] = useState(location.state.amount);
  const [couponsCommission, setCouponCommission] = useState(
    location.state.commission
  );
  const [couponQuantity, setCouponQuantity] = useState(location.state.quantity);
  const [couponsDiscount, setCouponDiscount] = useState(
    location.state.discount
  );
  const [youPaying, setYouPaying] = useState(location.state.youPaying);
  const [saving, setSaving] = useState(location.state.saving);
  const [brandShowHome, setBrandShowHoem] = useState(location.state.home);
  const [brandStatus, setBrandStatus] = useState(location.state.status);

  const [pointsDetails, setPointsDetails] = useState({
    Points: 0,
    RefPoints: 0,
    NxtRefPoints: 0,
    LogPoints: 0,
    ComPoolPoints: 0,
    FirstPurchPoints: 0,
    FirstPurchCompPoints: 0,
  });
  const [pointsDetailsPerc, setPointsDetailsPerc] = useState({
    Points: 0,
    RefPoints: 0,
    NxtRefPoints: 0,
    LogPoints: 0,
    ComPoolPoints: 0,
    FirstPurchace: 0,
  });

  // useEffect(() => {
  //   const commissionValue = (couponAmount * couponsCommission) / 100;

  //   const calculatePoints = (percentage) =>
  //     Math.round(commissionValue * percentage);

  //   setPointsDetails({
  //     Points: calculatePoints(0.2),
  //     RefPoints: calculatePoints(0.1),
  //     NxtRefPoints: calculatePoints(0.05),
  //     LogPoints: calculatePoints(0.1),
  //     ComPoolPoints: calculatePoints(0.55),
  //   });
  // }, [couponAmount, couponsCommission]);

  const [validated, setValidated] = useState(false);

  //image resizer
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        168,
        "jpg",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  // function clearFields() {
  //   setTitle("");
  //   setBrandName("Brands");
  //   setBrandId("");
  //   setStoreName("");
  //   setCouponCode("");
  //   setExpirationDateTime("");
  //   setCouponImage();
  //   setTermsAndConditions("");
  //   setCouponAmount("");
  //   setCouponQuantity("");
  //   setBrandStatus("Status");
  //   setBrandShowHoem(false);
  //   setCouponCommission("");
  //   setCouponDiscount("");
  //   imgRef.current.value = null;
  // }

  // async function handleSubmit(event) {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     setValidated(true);
  //     event.preventDefault();
  //     event.stopPropagation();
  //   } else {
  //     setValidated(false);
  //     event.preventDefault();
  //     try {
  //       let regex = new RegExp("[^.]+$");
  //       let extension = couponImage.name.match(regex);

  //       const uniqId = uniqid();

  //       let uniqueFileName = uniqId + "." + extension[0];

  //       const image = await resizeFile(couponImage);
  //       const params = {
  //         Bucket: "satyambucket",
  //         Key: uniqueFileName,
  //         Body: image,
  //       };

  //       s3.upload(params, function (err, data) {
  //         if (err) {
  //           throw err;
  //         } else {
  //           updateCouponForAdmin({
  //             variables: {
  //               couponInput: {
  //                 title: `${title}`,
  //                 brandName: `${brandName}`,
  //                 brandId: `${brandId}`,
  //                 storeName: `${storeName}`,
  //                 couponCode: `${couponCode}`,
  //                 expirationDateTime: `${expirationDateTime}`,
  //                 termsAndConditions: `${termsAndConditions}`,
  //                 image: `${uniqueFileName}`,
  //                 amount: parseFloat(couponAmount),
  //                 quantity: parseInt(couponQuantity),
  //                 commission: parseFloat(couponsCommission),
  //                 pointDetails: {
  //                   Points: parseFloat(pointsDetails.Points),
  //                   RefPoints: parseFloat(pointsDetails.RefPoints),
  //                   NxtRefPoints: parseFloat(pointsDetails.NxtRefPoints),
  //                   LogPoints: parseFloat(pointsDetails.LogPoints),
  //                   ComPoolPoints: parseFloat(pointsDetails.ComPoolPoints),
  //                   FirstPurchPoints: parseFloat(pointsDetails.FirstPurchPoints),
  //                   FirstPurchCompPoints: parseFloat(pointsDetails.FirstPurchCompPoints),
  //                 },
  //                 discount: parseFloat(couponsDiscount),
  //                 home: brandShowHome,
  //                 status: `${brandStatus}`,
  //               },

  //               "updateCouponForAdminInput": {
  //                 "couponMongoId": `${couponMongoId}`,
  //                 "title": `${title}`,
  //                 "brandName": `${brandName}`,
  //                 "categoryName": `${categoryName}`,
  //                 "brandId": `${brandId}`,
  //                 "categoryId": `${categoryId}`,
  //                 "vendorId": `${vendorId}`,
  //                 "storeName": `${storeName}`,
  //                 "couponCode": `${couponCode}`,
  //                 "expirationDateTime": `${expirationDateTime}`,
  //                 "termsAndConditions": `${termsAndConditions}`,
  //                 "image": `${uniqueFileName}`,
  //                 "amount": `${couponAmount}`,
  //                 "commission": `${couponsCommission}`,
  //                 "quantity": `${couponQuantity}`,
  //                 pointDetails: {
  //                   Points: parseFloat(pointsDetails.Points),
  //                   RefPoints: parseFloat(pointsDetails.RefPoints),
  //                   NxtRefPoints: parseFloat(pointsDetails.NxtRefPoints),
  //                   LogPoints: parseFloat(pointsDetails.LogPoints),
  //                   ComPoolPoints: parseFloat(pointsDetails.ComPoolPoints),
  //                   FirstPurchPoints: parseFloat(pointsDetails.FirstPurchPoints),
  //                   FirstPurchCompPoints: parseFloat(pointsDetails.FirstPurchCompPoints),
  //                 },
  //                 "discount": `${couponsDiscount}`,
  //                 "saving": `${saving}`,
  //                 "youPaying": `${youPaying}`,
  //                 "status": "Active"
  //               }
  //             },
  //           })
  //             .then((data) => {
  //               swal({
  //                 title: "Success",
  //                 text: "Coupon Added Successfully",
  //                 icon: "success",
  //               });
  //               // clearFields();
  //               // window.location.reload();
  //             })
  //             .catch((err) => alert(err));
  //         }
  //       });
  //     } catch (error) {
  //       console.log("Err", error);
  //     }
  //   }
  // }

  async function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      updateCouponForAdmin({
        variables: {
          updateCouponForAdminInput: {
            couponMongoId: `${couponMongoId}`,
            title: `${title}`,
            brandName: `${brandName}`,
            categoryName: `${categoryName}`,
            brandId: `${brandId}`,
            categoryId: `${categoryId}`,
            storeName: `${storeName}`,
            couponCode: `${couponCode}`,
            expirationDateTime: `${expirationDateTime}`,
            termsAndConditions: `${termsAndConditions}`,
            image: `${couponImage}`,
            amount: parseFloat(couponAmount),
            commission: parseFloat(couponsCommission),
            quantity: parseFloat(couponQuantity),
            points: parseFloat(pointsDetails.Points),
            refPoints: parseFloat(pointsDetails.RefPoints),
            nxtRefPoints: parseFloat(pointsDetails.NxtRefPoints),
            loginPoints: parseFloat(pointsDetails.LogPoints),
            companyPoints: parseFloat(pointsDetails.ComPoolPoints),
            FirstPurchPoints: parseFloat(pointsDetails.FirstPurchPoints),
            FirstPurchCompPoints: parseFloat(
              pointsDetails.FirstPurchCompPoints
            ),
            discount: parseFloat(couponsDiscount),
            saving: parseFloat(saving),
            youPaying: parseFloat(youPaying),
            status: "Active",
          },
        },
      }).then((data) => {
        swal({
          title: "Success",
          text: "Coupon Added Successfully",
          icon: "success",
        });
        navigation("/adminPanel/allCoupons");
        // clearFields();
        // window.location.reload();
      });
    }
  }

  function handlePointsSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setPointsValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setPointsValidated(false);
      event.preventDefault();
      const keyArr = ["Points", "RefPoints", "NxtRefPoints", "LogPoints"];
      const formObj = {};
      let sum = 0;
      for (let key of keyArr) {
        let value = Number(event.target[key].value / 100);
        formObj[key] = value;
        sum += value;
      }
      const stringSum = Number(1 - sum).toFixed(2);
      formObj.ComPoolPoints = parseFloat(stringSum);
      const commissionValue = (couponAmount * couponsCommission) / 100;
      const calculatePoints = (percentage) =>
        Math.round(commissionValue * percentage);
      const pointsArr = Object.keys(formObj);
      let points = {};
      for (let key of pointsArr) {
        points[key] = calculatePoints(formObj[key]);
      }
      points.FirstPurchPoints = Number(
        (commissionValue * event.target["FirstPurchace"].value) / 100
      );
      points.FirstPurchCompPoints = Number(
        commissionValue - points.FirstPurchPoints
      );
      formObj.FirstPurchace = event.target["FirstPurchace"].value;
      setPointsDetailsPerc(formObj);
      setPointsDetails(points);
      handleClose();
    }
  }

  return (
    <>
      <Container>
        <Row className="mx-4 ">
          <h2 className="page-title mb-3">Edit Coupon</h2>
          <Col className="shadow p-3 rounded ">
            <div className="box-shadow">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3 brands_form">
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Coupon Title <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Coupon Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Store Name <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Store Name"
                      value={storeName}
                      onChange={(e) => setStoreName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="3"
                    controlId="validationCustom02"
                    className="pt-4"
                  >
                    <Form.Label>
                      Choose The Brand :{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        size="sm"
                        className="rounded-pill w-100 mt-3  text-capitalize"
                      >
                        {brandName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {BrandList?.getBrandAll?.map(
                          ({ id, brandName }, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                setBrandId(id);
                                setBrandName(brandName);
                              }}
                            >
                              {brandName}
                            </Dropdown.Item>
                          )
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Coupon Code <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Coupon Code"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Coupon Amount <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="Coupon Amount"
                      value={couponAmount}
                      onChange={(e) => setCouponAmount(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Coupon Quantity{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="Coupon Quantity"
                      value={couponQuantity}
                      onChange={(e) => setCouponQuantity(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Expiration Date Time{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="date"
                      placeholder="Expiration Date Time"
                      value={expirationDateTime}
                      onChange={(e) => setExpirationDateTime(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Coupon Commission{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Row>
                      <Col md={10}>
                        <Form.Control
                          required
                          type="number"
                          placeholder="Coupon Commission"
                          value={couponsCommission}
                          onChange={(e) => setCouponCommission(e.target.value)}
                        />
                      </Col>
                      <Col md={2}>
                        {couponAmount !== "" && couponsCommission !== "" ? (
                          <Button onClick={() => setShow(true)}>Edit</Button>
                        ) : (
                          <Button disabled>Edit</Button>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Coupon Discount{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="Coupon Discount"
                      value={couponsDiscount}
                      onChange={(e) => setCouponDiscount(e.target.value)}
                    />
                  </Form.Group>

                  {/* <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Upload Image <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      ref={imgRef}
                      type="file"
                      className="txtinpt"
                      placeholder="Image"
                      onChange={(e) => setCouponImage(e.target.files[0])}
                    />
                  </Form.Group> */}
                  <Row md={12} className=" m-2 p-3 border rounded">
                    <h3>Points detail: </h3>
                    <Col md={2}>
                      <Row>
                        <Col>Price: CA$ {couponAmount}</Col>
                      </Row>
                      <Row>
                        <Col>Commission: {couponsCommission} %</Col>
                      </Row>
                    </Col>
                    <Col className="mb-3" md={10}>
                      <Row>
                        <Col>
                          Coupon Points{" "}
                          <span style={{ fontSize: 10 }}>
                            ({Number(pointsDetailsPerc.Points * 100).toFixed(2)}
                            %)
                          </span>{" "}
                          : {Number(pointsDetails.Points).toFixed(2)}
                        </Col>
                        <Col>
                          Referral points{" "}
                          <span style={{ fontSize: 10 }}>
                            (
                            {Number(pointsDetailsPerc.RefPoints * 100).toFixed(
                              2
                            )}
                            %)
                          </span>{" "}
                          : {Number(pointsDetails.RefPoints).toFixed(2)}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          Next referral points{" "}
                          <span style={{ fontSize: 10 }}>
                            (
                            {Number(
                              pointsDetailsPerc.NxtRefPoints * 100
                            ).toFixed(2)}
                            %)
                          </span>{" "}
                          : {Number(pointsDetails.NxtRefPoints).toFixed(2)}
                        </Col>
                        <Col>
                          Login points{" "}
                          <span style={{ fontSize: 10 }}>
                            (
                            {Number(pointsDetailsPerc.LogPoints * 100).toFixed(
                              2
                            )}
                            %)
                          </span>{" "}
                          : {Number(pointsDetails.LogPoints).toFixed(2)}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          First purchase Coupon Points{" "}
                          <span style={{ fontSize: 10 }}>
                            (
                            {Number(pointsDetailsPerc.FirstPurchace).toFixed(2)}
                            %)
                          </span>{" "}
                          : {Number(pointsDetails.FirstPurchPoints).toFixed(2)}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          Company pool points{" "}
                          <span style={{ fontSize: 10 }}>
                            (
                            {Number(
                              pointsDetailsPerc.ComPoolPoints * 100
                            ).toFixed(2)}
                            %)
                          </span>{" "}
                          : {Number(pointsDetails.ComPoolPoints).toFixed(2)}
                        </Col>
                        <Col>
                          First purchase Company Pool Points{" "}
                          <span style={{ fontSize: 10 }}>
                            (
                            {Number(
                              100 - pointsDetailsPerc.FirstPurchace
                            ).toFixed(2)}
                            %)
                          </span>{" "}
                          :{" "}
                          {Number(pointsDetails.FirstPurchCompPoints).toFixed(
                            2
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Add Terms And Conditions{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>

                    <Form.Control
                      required
                      as="textarea"
                      placeholder="Add Terms And Conditions here"
                      style={{ height: "100px" }}
                      value={termsAndConditions}
                      onChange={(e) => setTermsAndConditions(e.target.value)}
                    />
                  </Form.Group>
                  {/* <Form.Group
                    as={Col}
                    md="3"
                    controlId="validationCustom02"
                    className="pt-4"
                  >
                    <Form.Label>
                      Show On Home :{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <div className="form-check form-switch form-switch-md pt-3">
                      <input
                        required
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        onClick={() => setBrandShowHoem(!brandShowHome)}
                      />
                    </div>
                  </Form.Group> */}
                </Row>
                {pointsDetails.Points !== 0 ? (
                  <Button type="submit" className="d-block mx-auto  mt-5">
                    Edit & Active Coupon
                  </Button>
                ) : (
                  <Button disabled className="d-block mx-auto  mt-5">
                    Edit & Active Coupon
                  </Button>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      {show ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Points Details</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              noValidate
              validated={poitnsValidated}
              onSubmit={handlePointsSubmit}
            >
              <Row>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Coupons Points</Form.Label>
                  <Form.Control
                    required
                    id="Points"
                    name="Points"
                    type="number"
                    placeholder="Enter point %"
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Referral Points</Form.Label>
                  <Form.Control
                    required
                    id="RefPoints"
                    name="RefPoints"
                    type="number"
                    placeholder="Enter referral point %"
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Next Referral Points</Form.Label>
                  <Form.Control
                    required
                    id="NxtRefPoints"
                    name="NxtRefPoints"
                    type="number"
                    placeholder="Enter next referral point %"
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Loging Points</Form.Label>
                  <Form.Control
                    required
                    id="LogPoints"
                    name="LogPoints"
                    type="number"
                    placeholder="Enter loging point %"
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>First purchase points</Form.Label>
                  <Form.Control
                    required
                    id="FirstPurchace"
                    name="FirstPurchace"
                    type="number"
                    placeholder="Enter first purchase points %"
                  />
                </Form.Group>
              </Row>

              <Button size="sm" variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button size="sm" variant="danger" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
}
