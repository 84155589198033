import React from 'react'

export default function Down() {
    return (
        // <div style={{ textAlign: 'center', marginTop: 30 }}>Hosting and server are interrupted due to not paying of hosting charges.</div>
        <>
            
        </>
    )
}
