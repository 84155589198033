import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import "../../componentCss/login.css";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import Subnavbar from "../Subnavbar";
import { useMutation } from "@apollo/client";
import { ADD_VENDOR_MAIN } from "../../graphql/Mutations";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmailVerifier from "../EmailVerifier";
import AWS from "aws-sdk";
import uniqid from "uniqid";

const ID = "AKIA6GB4RFKTNZRHNUTV";
const SECRET = "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";
const BUCKET_NAME = "satyambucket";

const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

export default function VendorSignup() {
  const navigate = useNavigate();

  const [createVendor, { loading: vendorLoading, error }] =
    useMutation(ADD_VENDOR_MAIN);

  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    email: "",
    contact: "",
    businessName: "",
    businessType: "",
    businessNumberFile: null,
    businessRegistrationCertificate: null,
    storeAddress: ""
  });

  const [isVerified, setIsVerified] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({ ...formData, [name]: files ? files[0] : value });
  };

  const handleS3Upload = (file) => {
    return new Promise((resolve, reject) => {
      if (!file || !file.name) {
        resolve(null); // If no file or invalid file, resolve with null
        return;
      }
      const extension = file.name.split(".").pop();
      const uniqueFileName = `${uniqid()}.${extension}`;
      const params = {
        Bucket: BUCKET_NAME,
        Key: uniqueFileName,
        Body: file,
      };

      s3.upload(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(uniqueFileName);
        }
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Collect all file upload promises
      const uploadPromises = [
        handleS3Upload(formData.businessRegistrationCertificate),
        handleS3Upload(formData.businessNumberFile)
      ];

      // Await all uploads to complete
      const [
        businessRegistrationCertificateUrl,
        businessNumberFileUrl,
      ] = await Promise.all(uploadPromises);

      // Update formData with S3 URLs
      const updatedFormData = {
        ...formData,
        businessRegistrationCertificate: businessRegistrationCertificateUrl,
        businessNumberFile: businessNumberFileUrl,
      };

      console.log(updatedFormData);

      // Send the form data
      await createVendor({
        variables: {
          vendorMainInput: {
            firstName: updatedFormData.fName,
            lastName: updatedFormData.lName,
            email: updatedFormData.email,
            contact: updatedFormData.contact,
            password: password,
            businessName: updatedFormData.businessName,
            businessType: updatedFormData.businessType,
            businessRegistrationCertificate: updatedFormData.businessRegistrationCertificate,
            businessNumberFile: updatedFormData.businessNumberFile,
            storeAddress: updatedFormData.storeAddress,
          },
        },
      });

      toast.success("Register successfully!!!");
      navigate("/vendorLogin");
    } catch (err) {
      console.error("Error in handleSubmit:", err);
      toast.error("Failed to register. Please try again.");
    }
  };

  useEffect(() => {
    if (!password.length || !confirmPassword.length) {
      setPasswordMatch(false);
      return;
    }

    if (password === confirmPassword) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }, [password, confirmPassword]);

  return (
    <>
      <Navbar />
      <Subnavbar />

      <div className="login_main bg-white">
        <div>
          <div className="wrapper shadow bg-white">
            <div className="heading">
              <h2>Register with us!</h2>
              {error ? (
                <p style={{ color: "red" }}>{error.message}</p>
              ) : (
                <p style={{ color: "#2ecc71" }}>
                  Fill details for register with us
                </p>
              )}
            </div>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="fName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" name="fName" value={formData.fName} onChange={handleChange} required />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="lName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" name="lName" value={formData.lName} onChange={handleChange} required />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="contact">
                    <Form.Label>Contact</Form.Label>
                    <Form.Control type="text" name="contact" value={formData.contact} onChange={handleChange} required />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <EmailVerifier Email={formData.email} type={"Vendor"} done={setIsVerified} />
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    <Form.Text style={{ color: passwordMatch ? "green" : "red" }}>
                      {passwordMatch ? "Password Matched" : "Unmatched Password !"}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="confirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type="password" name="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                    <Form.Text style={{ color: passwordMatch ? "green" : "red" }}>
                      {passwordMatch ? "Password Matched" : "Unmatched Password !"}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              {/* Additional Vendor Registration Fields */}
              <Form.Group controlId="businessName" className="mt-3">
                <Form.Label>Business Name</Form.Label>
                <Form.Control required type="text" name="businessName" value={formData.businessName} onChange={handleChange} />
              </Form.Group>

              <Form.Group controlId="businessType" className="mt-3">
                <Form.Label>Business Type</Form.Label>
                <Form.Control required as="select" name="businessType" value={formData.businessType} onChange={handleChange}>
                  <option value="" disabled>
                    Select Business Type
                  </option>
                  <option value="Sole Proprietorship">
                    Sole Proprietorship
                  </option>
                  <option value="Partnership">Partnership</option>
                  <option value="Corporation">Corporation</option>
                </Form.Control>
              </Form.Group>

              <Row>
                <Col>
                  <Form.Group controlId="businessNumberFile" className="mt-3">
                    <Form.Label>Business Number (BN)</Form.Label>
                    <Form.Control required type="file" name="businessNumberFile" onChange={handleChange} />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="businessRegistrationCertificate">
                <Form.Label>Company Registration Number</Form.Label>
                <Form.Control required type="file" name="businessRegistrationCertificate" onChange={handleChange} />
              </Form.Group>
              <Form.Group controlId="storeAddress" className="mt-3">
                <Form.Label>Store Address</Form.Label>
                <Form.Control required type="text" name="storeAddress" value={formData.storeAddress} onChange={handleChange} />
              </Form.Group>

              <Button variant="primary" type="submit" className="mt-3" disabled={vendorLoading || !isVerified || !passwordMatch}>
                {vendorLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Register"
                )}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
