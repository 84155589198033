import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Dropdown,
} from "react-bootstrap";

import "./brands.css";
import swal from "sweetalert";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_BRAND } from "../../../graphql/Mutations";
import { GET_CATEGORY } from "../../../graphql/Query";

import AWS from "aws-sdk";
import uniqid from "uniqid";
import Resizer from "react-image-file-resizer";
import { parse } from "graphql";
const ID = "AKIA6GB4RFKTNZRHNUTV";
const SECRET = "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";
//const BUCKET_NAME = "satyambucket";
const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

export default function AddBrands() {
  const [createMutation] = useMutation(CREATE_BRAND);
  const { data: categoryList } = useQuery(GET_CATEGORY);

  const imgRef = useRef();

  const [brandName, setBrandName] = useState("");
  const [brandTitle, setBrandTitle] = useState("");
  const [brandUrl, setBrandUrl] = useState("");
  const [brandImage, setBrandImage] = useState("");
  const [brandCategoryName, setBrandCategoryName] = useState("Category");
  const [brandCategoryID, setBrandCategoryID] = useState("");
  const [brandDescription, setBrandDescription] = useState("");
  const [brandInstruction, setBrandInstruction] = useState("");
  const [brandRedeemInstruction, setBrandRedeemInstruction] = useState("");
  const [brandStatus, setBrandStatus] = useState("Status");
  const [brandShowHome, setBrandShowHoem] = useState(false);
  const [saving, setSaving] = useState("");

  const [validated, setValidated] = useState(false);

  function clearFields() {
    setBrandName("");
    setBrandTitle("");
    setBrandImage("");
    setBrandCategoryName("Category");
    setBrandCategoryID("");
    setBrandDescription("");
    setBrandInstruction("");
    setBrandRedeemInstruction("");
    setBrandStatus("Status");
    setBrandShowHoem(false);

    imgRef.current.value = null;
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "jpg",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  async function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      try {
        let regex = new RegExp("[^.]+$");
        let extension = brandImage.name.match(regex);

        const uniqId = uniqid();

        let uniqueFileName = uniqId + "." + extension[0];

        const image = brandImage;

        const params = {
          Bucket: "satyambucket",
          Key: uniqueFileName,
          Body: image,
        };

        s3.upload(params, function (err, data) {
          if (err) {
            throw err;
          } else {
            createMutation({
              variables: {
                brandInput: {
                  category: `${brandCategoryName}`,
                  categoryId: `${brandCategoryID}`,
                  brandName: `${brandName}`,
                  brandTitle: `${brandTitle}`,
                  brandUrl: `${brandUrl}`,
                  image: `${uniqueFileName}`,
                  description: `${brandDescription}`,
                  instructions: `${brandInstruction}`,
                  redeemInstructions: `${brandRedeemInstruction}`,
                  home: brandShowHome,
                  saving: parseInt(saving),
                  status: `${brandStatus}`,
                },
              },
            })
              .then((data) => {
                swal({
                  title: "Success",
                  text: "Brand Added Successfully",
                  icon: "success",
                });
                clearFields();
                window.location.reload();
              })
              .catch((err) => alert(err));
          }
        });
      } catch (error) {
        console.log("Err", error);
      }
    }
  }

  return (
    <>
      <Container>
        <Row className="mx-4 ">
          <h2 className="page-title mb-3">Add Brands </h2>
          <Col className="shadow p-3 rounded ">
            <div className="box-shadow">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3 brands_form">
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Brand Name <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Brand Name"
                      value={brandName}
                      onChange={(e) => setBrandName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Brand Title <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Brand Title"
                      value={brandTitle}
                      onChange={(e) => setBrandTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="9">
                    <Form.Label>
                      Brand URL <span className="required-asterisk">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Brand URL"
                      value={brandUrl}
                      onChange={(e) => setBrandUrl(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="3">
                    <Form.Label>
                      Savings <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="Savings"
                      value={saving}
                      onChange={(e) => setSaving(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Upload Image <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      ref={imgRef}
                      type="file"
                      className="txtinpt"
                      placeholder="Image"
                      onChange={(e) => setBrandImage(e.target.files[0])}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="3"
                    controlId="validationCustom02"
                    className="pt-4"
                  >
                    <Form.Label>
                      Choose The Categories :{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        size="sm"
                        className="rounded-pill w-100 mt-3 text-capitalize"
                      >
                        {brandCategoryName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {categoryList?.getCategory?.map(
                          (
                            { id, categoryName, createdDateTime, status },
                            index
                          ) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                setBrandCategoryID(id);
                                setBrandCategoryName(categoryName);
                              }}
                            >
                              {categoryName}
                            </Dropdown.Item>
                          )
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      Add Description{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>

                    <Form.Control
                      required
                      as="textarea"
                      placeholder="Add Description here"
                      style={{ height: "100px" }}
                      value={brandDescription}
                      onChange={(e) => setBrandDescription(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Add Instruction{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      required
                      placeholder="Add Instruction here"
                      style={{ height: "100px" }}
                      value={brandInstruction}
                      onChange={(e) => setBrandInstruction(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Redeem Instruction{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>

                    <Form.Control
                      required
                      as="textarea"
                      placeholder="Add Redeem Instruction here"
                      style={{ height: "100px" }}
                      value={brandRedeemInstruction}
                      onChange={(e) =>
                        setBrandRedeemInstruction(e.target.value)
                      }
                    />
                  </Form.Group>
                  {/* <Form.Group
                    as={Col}
                    md="3"
                    controlId="validationCustom02"
                    className="pt-4"
                  >
                    <Form.Label>
                      Select Status :{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        size="sm"
                        className="rounded-pill w-100 mt-3 text-capitalize"
                      >
                        {brandStatus}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setBrandStatus("Active")}>
                          Active
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => setBrandStatus("Inactive")}
                        >
                          Inactive
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => setBrandStatus("Pending")}
                        >
                          Pending
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group> */}
                  <Form.Group
                    as={Col}
                    md="3"
                    controlId="validationCustom02"
                    className="pt-4"
                  >
                    <Form.Label>
                      Show On Home :{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <div className="form-check form-switch form-switch-md pt-3">
                      <input
                        required
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        onClick={() => setBrandShowHoem(!brandShowHome)}
                      />
                    </div>
                  </Form.Group>
                </Row>

                <Button type="submit" className="d-block mx-auto  mt-5">
                  Add Brand
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
