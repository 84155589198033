import React, { useState } from "react";
import "../componentCss/subnavbar.css";
import { IoMdHome } from "react-icons/io";
import { Link } from "react-router-dom";
import { RiCoupon3Line } from "react-icons/ri";
import { useQuery } from "@apollo/client";
import { GET_CATEGORY } from "../graphql/Query";
import Loader from "./Loader";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SplitButton from "react-bootstrap/SplitButton";
import { Form, Modal } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Subnavbar = () => {
  const { data: categoryList } = useQuery(GET_CATEGORY);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const notify = () => toast.error("This funtionality will be coming soon...");

  return (
    <>
      {/* <ToastContainer closeOnClick closeButton={false} theme="light" /> */}
      <div className="subnavbar_main py-3 px-md-4 ps-md-4 ps-2">
        <div className="container-fluid p-0">
          <div className="row m-0 d-flex align-items-start">
            <div className="col-md-10 position-relative col-6 m-0 d-flex align-items-center gap-4 p-0 ps-2">
              <Link to="/">
                <div className="home_icon d-flex align-items-center justify-content-center">
                  <IoMdHome className="fs-4 text-white" />
                </div>
              </Link>

              <div className="categories_links d-md-flex d-none">
                {categoryList?.getCategory ? (
                  categoryList.getCategory
                    .slice(0, 4)
                    .map(({ id, categoryName, categoryBannerImage }, index) => (
                      <div key={index} className="category_link me-md-5 me-3">
                        <Link
                          to={`/categories/${id}/${categoryName}/${categoryBannerImage}`}
                        >
                          <span>{categoryName}</span>
                        </Link>
                      </div>
                    ))
                ) : (
                  <Loader />
                )}
              </div>

              <div className="dropdown_subnav me-md-4 me-2">
                <div>
                  <DropdownButton
                    as={ButtonGroup}
                    id="dropdown-button-drop"
                    size="sm"
                    variant=""
                    title="All Categories"
                    style={{
                      border: "none",
                      background: "#E2E2FF",

                      outline: "none",
                      borderColor: "transparent",
                      paddingBottom: "0px",
                    }}
                  >
                    {categoryList?.getCategory ? (
                      categoryList.getCategory.map(
                        ({ id, categoryName, categoryBannerImage }, index) => (
                          <Dropdown.Item key={index} eventKey="2">
                            <Link
                              to={`/categories/${id}/${categoryName}/${categoryBannerImage}`}
                              className="text-decoration-none text-black"
                            >
                              <span>{categoryName}</span>
                            </Link>
                          </Dropdown.Item>
                        )
                      )
                    ) : (
                      <Loader />
                    )}
                  </DropdownButton>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6 m-0 p-md-0 px-2 mb-md-0 mb-3">
              <div
                // onClick={handleShow}
                onClick={notify}
                className="voucher_status d-flex align-items-center justify-content-center py-2 text-white"
              >
                <RiCoupon3Line className="me-1 " />{" "}
                <span style={{ fontSize: 12 }}>Check Coupon</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title
            className="text-center"
            style={{ height: "100%", width: "100%" }}
          >
            Check Voucher Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Voucher Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Voucher Code"
                style={{ border: "1px solid #E2E2FF" }}
              />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <button className="btn btn-primary">Check Status</button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Subnavbar;
