import { gql } from "@apollo/client";

export const GET_POOL = gql`
  query GetPool {
    getPool {
      points
      lastUpdate
      status
      freezed
    }
  }
`;

export const GET_POOL_DETAILS = gql`
  query Query {
    getPoolDetail {
      id
      action
      dateAndTime
      actionId
      prePoitns
      newPoitns
    }
  }
`;

export const GET_POOL_DETAILS_BY_DATE = gql`
  query Query($filterDate: filterDate) {
    getPoolDetailByDate(FilterDate: $filterDate) {
      id
      action
      dateAndTime
      actionId
      prePoitns
      newPoitns
    }
  }
`;

export const GET_CATEGORY = gql`
  query GetCategory {
    getCategory {
      id
      categoryBannerImage
      categoryName
      createdDateTime
      status
    }
  }
`;

export const GET_ALLBRAND = gql`
  query Query {
    getBrandAll {
      id
      category
      categoryId
      brandName
      brandTitle
      brandUrl
      image
      description
      instructions
      redeemInstructions
      createdDateTime
      home
      saving
      status
    }
  }
`;
export const GET_BRAND_BY_CATEGORY = gql`
  query GetBrand($categoryId: ID) {
    getBrand(CategoryId: $categoryId) {
      id
      category
      categoryId
      brandName
      brandTitle
      brandUrl
      image
      description
      instructions
      redeemInstructions
      createdDateTime
      home
      saving
      status
    }
  }
`;
export const GET_BRAND_BY_ID = gql`
  query GetBrandByID($brandId: ID) {
    getBrandByID(BrandId: $brandId) {
      id
      category
      categoryId
      brandName
      brandTitle
      brandUrl
      image
      description
      instructions
      redeemInstructions
      createdDateTime
      home
      status
    }
  }
`;

export const GET_BRAND_BY_SAVING = gql`
  query Query($discountWiseBrand: discountWiseBrand) {
    getBrandDiscountWise(DiscountWiseBrand: $discountWiseBrand) {
      id
      image
      brandName
      saving
      status
    }
  }
`;
/* <----------------------------------------- Coupon Section Start -----------------------------------------------------------> */

export const SEARCH_COUPONS_BY_NAME = gql`
  query SearchCoupons($name: String) {
    searchCoupons(Name: $name) {
      id
      title
      couponId
      brandName
      brandId
      vendorId
      storeName
      couponCode
      expirationDateTime
      termsAndConditions
      image
      amount
      commission
      quantity
      points
      refPoints
      nxtRefPoints
      loginPoints
      companyPoints
      FirstPurchPoints
      FirstPurchCompPoints
      discount
      saving
      youPaying
      createdDateTime
      home
      status
    }
  }
`;

export const GET_COUPON_BRAND_ID = gql`
  query GetCoupon($brandId: ID) {
    getCoupon(BrandId: $brandId) {
      id
      title
      couponId
      brandName
      categoryName
      brandId
      vendorId
      storeName
      couponCode
      expirationDateTime
      termsAndConditions
      image
      amount
      commission
      quantity
      points
      commission
      refPoints
      nxtRefPoints
      loginPoints
      companyPoints
      FirstPurchPoints
      FirstPurchCompPoints
      discount
      saving
      youPaying
      createdDateTime
      home
      status
    }
  }
`;

export const GetAllCoupon = gql`
  query GetCouponAll {
    getCouponAll {
      id
      title
      couponId
      brandName
      categoryName
      brandId
      categoryId
      vendorId
      storeName
      couponCode
      expirationDateTime
      termsAndConditions
      image
      amount
      commission
      quantity
      points
      refPoints
      nxtRefPoints
      loginPoints
      companyPoints
      FirstPurchPoints
      FirstPurchCompPoints
      discount
      saving
      youPaying
      createdDateTime
      home
      status
    }
  }
`;
export const GET_PENDING_COUPONS = gql`
  query Query {
    getPendingCoupon {
      id
      title
      couponId
      brandName
      brandId
      vendorId
      storeName
      couponCode
      expirationDateTime
      termsAndConditions
      image
      amount
      commission
      quantity
      points
      discount
      saving
      youPaying
      createdDateTime
      home
      status
    }
  }
`;
export const GET_ACTIVE_COUPONS = gql`
  query GetActiveCoupon {
    getActiveCoupon {
      id
      title
      couponId
      brandName
      brandId
      vendorId
      storeName
      couponCode
      expirationDateTime
      termsAndConditions
      image
      amount
      commission
      quantity
      points
      discount
      saving
      youPaying
      createdDateTime
      home
      status
    }
  }
`;
export const GET_INACTIVE_COUPONS = gql`
  query GetInActiveCoupon {
    getInActiveCoupon {
      id
      title
      couponId
      brandName
      brandId
      vendorId
      storeName
      couponCode
      expirationDateTime
      termsAndConditions
      image
      amount
      commission
      quantity
      points
      discount
      saving
      youPaying
      createdDateTime
      home
      status
    }
  }
`;
/* <----------------------------------------- Coupon Section End -----------------------------------------------------------> */

/* <----------------------------------------- Banner Section Start -----------------------------------------------------------> */
export const GetBanner = gql`
  query GetBanner {
    getBanner {
      id
      image
      bannerTitle
      bannerUrl
      other
      location
      brandName
      brandId
      createdDateTime
      status
    }
  }
`;
/* <----------------------------------------- Banner Section End -----------------------------------------------------------> */

/* <----------------------------------------- Treanding Section Start -----------------------------------------------------------> */
export const GET_TRENDINGDEAL = gql`
  query Query {
    getTrendingDeal {
      id
      dealName
      image
      description
      dealUrl
      createdDateTime
      status
    }
  }
`;
/* <----------------------------------------- Treanding Section End -----------------------------------------------------------> */

/* <----------------------------------------- Top Offers Start -----------------------------------------------------------> */
export const TopOffers = gql`
  query GetTopOffer {
    getTopOffer {
      id
      title
      couponId
      brandName
      brandId
      storeName
      couponCode
      expirationDateTime
      termsAndConditions
      image
      amount
      quantity
      points
      discount
      saving
      youPaying
      createdDateTime
      home
      status
    }
  }
`;
/* <----------------------------------------- Top Offers End  -----------------------------------------------------------> */

/* <----------------------------------------- Top Brands Start -----------------------------------------------------------> */

export const SEARCH_BRANDS = gql`
  query Query($searchBrandInput: searchBrandInput) {
    searchBrand(SearchBrandInput: $searchBrandInput) {
      id
      category
      categoryId
      brandName
      brandTitle
      brandUrl
      image
      description
      instructions
      redeemInstructions
      createdDateTime
      home
      saving
      status
    }
  }
`;

export const TopBrands = gql`
  query GetTopBrand {
    getTopBrand {
      id
      category
      categoryId
      brandName
      brandTitle
      image
      description
      instructions
      redeemInstructions
      createdDateTime
      home
      status
    }
  }
`;
/* <----------------------------------------- Top Brands End  -----------------------------------------------------------> */

/* <----------------------------------------- User Start  -----------------------------------------------------------> */

export const GET_USER_BY_ID = gql`
  query Query($userId: ID) {
    getUserById(UserId: $userId) {
      email
      points {
        loginPoints
        referralPoints
        couponPoints
        totalPoints
      }
    }
  }
`;

export const GetAllUser = gql`
  query GetUser {
    getUser {
      id
      firstName
      lastName
      email
      contact
      image
      points {
        loginPoints
        referralPoints
        couponPoints
        totalPoints
      }
      earning
      refferalLevel
      createdDateTime
      status
    }
  }
`;

export const GET_USER_BY_TOKEN = gql`
  query GetUserByToken($token: String) {
    getUserByToken(Token: $token) {
      id
      firstName
      lastName
      email
      contact
      image
      points {
        loginPoints
        referralPoints
        couponPoints
        totalPoints
      }
      earning
      refferalLevel
      createdDateTime
      status
    }
  }
`;
export const UPDATE_USER = gql`
  mutation UpdateUser($userUpdateInput: userUpdateInput) {
    updateUser(UserUpdateInput: $userUpdateInput) {
      id
      firstName
      lastName
      email
      contact
      image
      points {
        loginPoints
        referralPoints
        couponPoints
        totalPoints
      }
      earning
      refferalLevel
      createdDateTime
      status
    }
  }
`;
/* <----------------------------------------- User End  -----------------------------------------------------------> */
/* <----------------------------------------- order start  -----------------------------------------------------------> */

export const GET_ORDER_BY_ORDERID = gql`
  query Query($orderId: String) {
    getOrderView(OrderId: $orderId) {
      id
      orderId
      userId
      totalOrderPaying
      totalOrderPoints
      orderedDateTime
      status
    }
  }
`;

export const GET_ORDER_BY_TOKEN = gql`
  query Query($token: String) {
    getOrderByToken(Token: $token) {
      id
      orderId
      userId
      orderedCoupons {
        id
        title
        couponId
        brandName
        storeName
        couponCode
        expirationDateTime
        termsAndConditions
        image
        amount
        quantity
        points
        discount
        saving
        youPaying
        createdDateTime
        status
        qty
        totalPoints
        totalPaying
      }
      totalOrderPaying
      totalOrderPoints
      orderedDateTime
      sessionID
      transactionID
      status
    }
  }
`;

export const GET_ORDER_BY_USERID = gql`
  query Query($userId: ID) {
    getOrder(UserId: $userId) {
      id
      orderId
      userId
      orderedCoupons {
        id
        title
        couponId
        brandName
        storeName
        couponCode
        expirationDateTime
        termsAndConditions
        image
        amount
        quantity
        points
        discount
        saving
        youPaying
        createdDateTime
        status
        qty
        totalPoints
        totalPaying
      }
      totalOrderPaying
      totalOrderPoints
      orderedDateTime
      status
    }
  }
`;

export const GET_ALL_ORDERS = gql`
  query Query {
    getAllOrder {
      id
      orderId
      userId
      orderedCoupons {
        id
        title
        couponId
        vendorId
        brandName
        storeName
        couponCode
        expirationDateTime
        termsAndConditions
        image
        amount
        commission
        quantity
        points
        refPoints
        nxtRefPoints
        loginPoints
        companyPoints
        FirstPurchPoints
        FirstPurchCompPoints
        discount
        saving
        youPaying
        createdDateTime
        status
        qty
        totalPoints
        totalPaying
      }
      totalOrderPaying
      totalOrderPoints
      orderedDateTime
      status
    }
  }
`;

export const GET_ORDER_BY_DATE = gql`
  query GetOrderByDate($filterDate: filterDate) {
    getOrderByDate(FilterDate: $filterDate) {
      id
      orderId
      userId
      orderedCoupons {
        id
        title
        couponId
        vendorId
        brandName
        storeName
        couponCode
        expirationDateTime
        termsAndConditions
        image
        amount
        commission
        quantity
        points
        refPoints
        nxtRefPoints
        loginPoints
        companyPoints
        FirstPurchPoints
        FirstPurchCompPoints
        discount
        saving
        youPaying
        createdDateTime
        status
        qty
        totalPoints
        totalPaying
      }
      totalOrderPaying
      totalOrderPoints
      orderedDateTime
      status
    }
  }
`;

/* <----------------------------------------- order End  -----------------------------------------------------------> */
/* <----------------------------------------- vendor Start  -----------------------------------------------------------> */

export const GET_PENDING_VENDORS = gql`
  query Query {
    getPendingVendorsMain {
      id
      vendorUniqueId
      firstName
      lastName
      email
      contact
      businessName
      contactInformation
      businessType
      createdDateTime
      status
    }
  }
`;
export const GET_ACTIVE_VENDORS = gql`
  query Query {
    getActiveVendorsMain {
      id
      vendorUniqueId
      firstName
      lastName
      email
      contact
      businessName
      contactInformation
      businessType
      createdDateTime
      status
    }
  }
`;

export const GET_ALL_VENDORS = gql`
  query Query {
    getAllVendors {
      id
      vendorUniqueId
      firstName
      lastName
      email
      contact
      businessName
      contactInformation
      businessType
      createdDateTime
      status
    }
  }
`;
/* <----------------------------------------- vendor End  -----------------------------------------------------------> */
export const GET_ALL_SUBSCRIBERS = gql`
  query Query {
    getAllSubscribers {
      id
      email
      createdDateTime
      status
    }
  }
`;

export const GET_COUPON_BY_VENDOR_BY_ID = gql`
  query GetCouponByVendorMainId($vendorMainId: ID) {
    getCouponByVendorMainId(vendorMainId: $vendorMainId) {
      id
      title
      couponId
      brandName
      categoryName
      brandId
      categoryId
      vendorId
      storeName
      couponCode
      expirationDateTime
      termsAndConditions
      image
      amount
      commission
      quantity
      points
      refPoints
      nxtRefPoints
      loginPoints
      companyPoints
      FirstPurchPoints
      FirstPurchCompPoints
      discount
      saving
      youPaying
      createdDateTime
      home
      status
    }
  }
`;
export const GET_ORDER_BY_VENDOR_BY_ID = gql`
  query GetCouponsByVendorId($vendorId: ID) {
    getCouponsByVendorId(vendorId: $vendorId) {
      orderId
      orderedDateTime
      coupons {
        id
        title
        couponId
        brandName
        categoryName
        brandId
        categoryId
        vendorId
        storeName
        couponCode
        expirationDateTime
        termsAndConditions
        image
        amount
        commission
        quantity
        points
        refPoints
        nxtRefPoints
        loginPoints
        companyPoints
        FirstPurchPoints
        FirstPurchCompPoints
        discount
        saving
        youPaying
        createdDateTime
        home
        status
      }
    }
  }
`;
export const GET_VENDOR_BY_ID = gql`
  query GetVendorMainById($vendorMainId: ID) {
    getVendorMainById(vendorMainId: $vendorMainId) {
      id
      vendorUniqueId
      firstName
      lastName
      email
      contact
      businessName
      contactInformation
      businessType
      productCategories
      pricingInformation
      shippingInformation
      returnPolicy
      vendorAgreement
      digitalSignatureCertificate
      warehouseDetails
      documents
      businessRegistrationCertificate
      businessNumberFile
      gstHstRegistration
      bankAccountDetails
      identityProof
      insuranceProof
      addressProof
      productListings
      supplierAgreements
      sin
      companyRegistrationNumber
      storeAddress
      createdDateTime
      status
      createdAt
      updatedAt
    }
  }
`;

export const GET_STORES_BY_LOCATION = gql`
  query GetStoreLocation($keyword: String) {
    getStoreLocation(Keyword: $keyword) {
      businessName
      contact
      email
      firstName
      id
      lastName
      productCategories
      storeAddress
      vendorUniqueId
      warehouseDetails
    }
  }
`;

// --------------------------------Redeem---start-------------------------------------

export const GET_REDEEM_VALUE = gql`
  query Query {
    getRedeemValue
  }
`;

// --------------------------------Redeem---end-------------------------------------
