import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Container, Row, Col } from "react-bootstrap";
import { TiTick } from "react-icons/ti";
import { IoMdClose } from "react-icons/io";

function PaymentStatus() {
  // Custom hook to parse URL query parameters
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const status = query.get("status");
  const amount = query.get("amount");
  const message = query.get("message");

  const navigate = useNavigate();

  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Row className="justify-content-center">
        <Col md={12}>
          {status === "success" ? (
            <Card className="px-5 py-3">
              <Card.Body className="d-flex align-items-center justify-content-center flex-column gap-2 px-3 py-3">
                <span
                  style={{ width: "90px", height: "90px", borderRadius: "50%" }}
                  className="border shadow bg-success d-flex align-items-center justify-content-center"
                >
                  <TiTick size={33} color="#fff" />
                </span>
                <br />
                <Card.Title>
                  {" "}
                  <b>Amount : {amount || "00"} CAD</b>{" "}
                </Card.Title>
                <Card.Title>Payment Successful</Card.Title>
                <Card.Text>
                  {message || "Your payment was received successfully."}
                </Card.Text>
                <button
                  onClick={() => navigate("/user/userCoupons")}
                  className="btn btn-success rounded-5"
                >
                  Go to main page
                </button>
              </Card.Body>
            </Card>
          ) : (
            <Card className="px-5 py-3">
              <Card.Body className="d-flex align-items-center justify-content-center flex-column gap-2 px-3 py-3">
                <span
                  style={{ width: "90px", height: "90px", borderRadius: "50%" }}
                  className="border shadow bg-danger d-flex align-items-center justify-content-center"
                >
                  <IoMdClose size={33} color="#fff" />
                </span>
                <br />
                {amount && (
                  <>
                    <Card.Title>
                      {" "}
                      <b>Amount : {amount || "00"} CAD</b>{" "}
                    </Card.Title>
                  </>
                )}
                <Card.Title>Payment Failed</Card.Title>
                <Card.Text>
                  {message || "There was an issue with your payment."}
                </Card.Text>
                <button
                  onClick={() => navigate("/user/userCoupons")}
                  className="btn btn-danger rounded-5"
                >
                  go to main page
                </button>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default PaymentStatus;
