import React, { useState } from "react";
import { Button, Form, Modal, Row, Col, Dropdown } from "react-bootstrap";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALLBRAND, GET_CATEGORY } from "../../../graphql/Query";
import { UPDATE_CATEGORY } from "../../../graphql/Mutations";
import swal from "sweetalert";

import AWS from "aws-sdk";
import uniqid from "uniqid";
const ID = "AKIA6GB4RFKTNZRHNUTV";
const SECRET = "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";
//const BUCKET_NAME = "satyambucket";
const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

export default function CategoryEditModal({ show, setShow, data }) {
  const [updateCategory] = useMutation(UPDATE_CATEGORY);

  const { id, categoryName, categoryBannerImage, createdDateTime, status } =
    data;
  const [validated, setValidated] = useState(false);
  const [categoryNameedit, setCategoryName] = useState(categoryName);
  const [categoryBannerImageEdit, setCategoryBannerImageEdit] =
    useState(categoryBannerImage);
  const [statusedit, setStatus] = useState(status);

  async function handleImageChange(file) {
    if (!file) {
      return false;
    } else {
      try {
        let regex = new RegExp("[^.]+$");
        let extension = file.name.match(regex);

        const uniqId = uniqid();

        let uniqueFileName = uniqId + "." + extension[0];

        const image = file;

        const params = {
          Bucket: "satyambucket",
          Key: uniqueFileName,
          Body: image,
        };
        s3.upload(params, function (err, data) {
          if (!err) {
            setCategoryBannerImageEdit(uniqueFileName);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      updateCategory({
        variables: {
          categoryUpdateInput: {
            id: `${id}`,
            categoryBannerImage: `${categoryBannerImageEdit}`,
            categoryName: `${categoryNameedit}`,
            status: `${statusedit}`,
          },
        },
      })
        .then((data) => {
          swal({
            title: "Success",
            text: "Category Updated Successfully",
            icon: "success",
          });
          setShow(false);
          window.location.reload();
        })
        .catch((err) => alert(err));
    }
  }

  return (
    <div>
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="box-shadow">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3 brands_form">
                <Form.Group className="mb-3" as={Col} md="12">
                  <Form.Label>
                    Category Image Banner{" "}
                    <span className="required-asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="file"
                    placeholder="Category Image Banner"
                    onChange={async (e) =>
                      await handleImageChange(e.target.files[0])
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Category Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Category Name"
                    value={categoryNameedit}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="3"
                  controlId="validationCustom02"
                  className="pt-4"
                >
                  <Form.Label>Select Status :</Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom02">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      size="sm"
                      className="rounded-pill w-100 mt-3 text-capitalize"
                    >
                      {statusedit}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setStatus("Active")}>
                        Active
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setStatus("Inactive")}>
                        Inactive
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setStatus("Empty")}>
                        {" "}
                        Empty
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Button type="submit" className="d-block mx-auto  mt-5">
                Add Category
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
