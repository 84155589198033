import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import DataStore from "./contextApi.js";
import { AuthProvider } from "./context/AuthProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  // uri: "http://localhost:4000/graphql",
  uri: "https://couponback.vercel.app/graphql",
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <DataStore>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <AuthProvider>
            <ToastContainer
              position="top-center"
              theme="colored"
              autoClose={2000}
            />
            <App />
          </AuthProvider>
        </BrowserRouter>
      </ApolloProvider>
    </DataStore>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
