import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Dropdown } from "react-bootstrap";
import "../brands.css";
import swal from "sweetalert";
import Resizer from "react-image-file-resizer";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_VENDOR_COUPON } from "../../../graphql/Mutations";
import { GET_ALLBRAND, GET_CATEGORY } from "../../../graphql/Query";

import AWS from "aws-sdk";
import uniqid from "uniqid";
const ID = "AKIA6GB4RFKTNZRHNUTV";
const SECRET = "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";
const BUCKET_NAME = "satyambucket";
const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

export default function AddVendorCoupons() {
  const [createMutation] = useMutation(ADD_VENDOR_COUPON);
  const { data: BrandList } = useQuery(GET_ALLBRAND);
  const { data: CategoryList } = useQuery(GET_CATEGORY);

  const imgRef = useRef();

  const vendorId = localStorage.getItem('vendorId');

  const [poitnsValidated, setPointsValidated] = useState(false);

  const [title, setTitle] = useState("");
  const [couponId, setCouponId] = useState("");
  const [brandName, setBrandName] = useState("Brands");
  const [categoryName, setCategoryName] = useState("Category");
  const [categoryId, setCategoryId] = useState("Category");
  const [brandId, setBrandId] = useState("");
  const [storeName, setStoreName] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [expirationDateTime, setExpirationDateTime] = useState("");
  const [couponImage, setCouponImage] = useState();
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [couponAmount, setCouponAmount] = useState("");
  const [couponQuantity, setCouponQuantity] = useState("");
  const [brandStatus, setBrandStatus] = useState("Status");
  const [brandShowHome, setBrandShowHoem] = useState(false);
  const [couponsCommission, setCouponCommission] = useState("");
  const [couponsDiscount, setCouponDiscount] = useState("");

  // useEffect(() => {
  //   const commissionValue = (couponAmount * couponsCommission) / 100;

  //   const calculatePoints = (percentage) =>
  //     Math.round(commissionValue * percentage);

  //   setPointsDetails({
  //     Points: calculatePoints(0.2),
  //     RefPoints: calculatePoints(0.1),
  //     NxtRefPoints: calculatePoints(0.05),
  //     LogPoints: calculatePoints(0.1),
  //     ComPoolPoints: calculatePoints(0.55),
  //   });
  // }, [couponAmount, couponsCommission]);

  const [validated, setValidated] = useState(false);

  //image resizer
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        168,
        "jpg",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  function clearFields() {
    setTitle("");
    setCouponId("");
    setBrandName("Brands");
    setCategoryName("Category");
    setBrandId("");
    setCategoryId("");
    setStoreName("");
    setCouponCode("");
    setExpirationDateTime("");
    setCouponImage();
    setTermsAndConditions("");
    setCouponAmount("");
    setCouponQuantity("");
    setBrandStatus("Status");
    setBrandShowHoem(false);
    setCouponCommission("");
    setCouponDiscount("");
    imgRef.current.value = null;
  }

  async function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      try {
        let regex = new RegExp("[^.]+$");
        let extension = couponImage.name.match(regex);

        const uniqId = uniqid();

        let uniqueFileName = uniqId + "." + extension[0];

        const image = await resizeFile(couponImage);
        const params = {
          Bucket: "satyambucket",
          Key: uniqueFileName,
          Body: image,
        };

        s3.upload(params, function (err, data) {
          if (err) {
            throw err;
          } else {
            createMutation({
              variables: {
                couponInput: {
                  title: `${title}`,
                  couponId: `${couponId}`,
                  brandName: `${brandName}`,
                  categoryName: `${categoryName}`,
                  brandId: `${brandId}`,
                  categoryId: `${categoryId}`,
                  vendorId: `${vendorId}`,
                  storeName: `${storeName}`,
                  couponCode: `${couponCode}`,
                  expirationDateTime: `${expirationDateTime}`,
                  termsAndConditions: `${termsAndConditions}`,
                  image: `${uniqueFileName}`,
                  amount: parseFloat(couponAmount),
                  quantity: parseInt(couponQuantity),
                  commission: parseFloat(couponsCommission),
                  discount: parseFloat(couponsDiscount),
                  home: true,
                },
              },
            })
              .then((data) => {
                swal({
                  title: "Success",
                  text: "Coupon Added Successfully",
                  icon: "success",
                });
                clearFields();
                window.location.reload();
              })
              .catch((err) => alert(err));
          }
        });
      } catch (error) {
        console.log("Err", error);
      }
    }
  }


  return (
    <>
      <Container>
        <Row className="mx-4 ">
          <h2 className="page-title mb-3">Add Coupon</h2>
          <Col className="shadow p-3 rounded ">
            <div className="box-shadow">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3 brands_form">
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Coupon Title <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control required type="text" placeholder="Coupon Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                  </Form.Group>

                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Store Name <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control required type="text" placeholder="Store Name" value={storeName} onChange={(e) => setStoreName(e.target.value)} />
                  </Form.Group>

                  <Form.Group as={Col} md="3" controlId="validationCustom02" className="pt-4">
                    <Form.Label>
                      Choose Brand :{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm" className="rounded-pill w-100 mt-3  text-capitalize">
                        {brandName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {BrandList?.getBrandAll?.map(
                          ({ id, brandName }, index) => (
                            <Dropdown.Item key={index} onClick={() => { setBrandId(id); setBrandName(brandName); }}>
                              {brandName}
                            </Dropdown.Item>
                          )
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>

                  <Form.Group as={Col} md="3" controlId="validationCustom02" className="pt-4">
                    <Form.Label>
                      Choose Category :{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm" className="rounded-pill w-100 mt-3  text-capitalize">
                        {categoryName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {CategoryList?.getCategory?.map(
                          ({ id, categoryName }, index) => (
                            <Dropdown.Item key={index} onClick={() => { setCategoryId(id); setCategoryName(categoryName); }}>
                              {categoryName}
                            </Dropdown.Item>
                          )
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>

                  <Form.Group className="mb-3 pt-4" as={Col} md="6">
                    <Form.Label>
                      Coupon Code <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control required type="text" placeholder="Coupon Code" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3 pt-4" as={Col} md="6">
                    <Form.Label>
                      Coupon Amount <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control required type="number" placeholder="Coupon Amount" value={couponAmount} onChange={(e) => setCouponAmount(e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Coupon Quantity{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control required type="number" placeholder="Coupon Quantity" value={couponQuantity} onChange={(e) => setCouponQuantity(e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Expiration Date Time{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control required type="date" placeholder="Expiration Date Time" value={expirationDateTime} onChange={(e) => setExpirationDateTime(e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Coupon Commission{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control required type="number" placeholder="Coupon Commission" value={couponsCommission} onChange={(e) => setCouponCommission(e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Coupon Discount{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control required type="number" placeholder="Coupon Discount" value={couponsDiscount} onChange={(e) => setCouponDiscount(e.target.value)} />
                  </Form.Group>

                  <Form.Group className="mb-3" as={Col} md="6">
                    <Form.Label>
                      Upload Image <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control required ref={imgRef} type="file" className="txtinpt" placeholder="Image" onChange={(e) => setCouponImage(e.target.files[0])} />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Add Terms And Conditions{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                    <Form.Control required as="textarea" placeholder="Add Terms And Conditions here" style={{ height: "100px" }} value={termsAndConditions} onChange={(e) => setTermsAndConditions(e.target.value)} />
                  </Form.Group>
                  {/* <Form.Group as={Col} md="3" controlId="validationCustom02" className="pt-4">
                    <Form.Label>
                      Show On Home :{" "}
                      <span className="required-asterisk">*</span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <div className="form-check form-switch form-switch-md pt-3">
                      <input required className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onClick={() => setBrandShowHoem(!brandShowHome)} />
                    </div>
                  </Form.Group> */}
                </Row>
                <Button type="submit" className="d-block mx-auto  mt-5">
                  Add Coupon
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
