import React, { useContext, useState } from "react";
import Navbar from "../components/Navbar";
import "../componentCss/user/user.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { BiSolidCoupon } from "react-icons/bi";
import { IoIosSettings } from "react-icons/io";
import { IoMdLogOut } from "react-icons/io";
import swal from "sweetalert";
import Footer from "../components/Footer";
import { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_USER_BY_TOKEN, UPDATE_USER } from "../graphql/Query";
import AuthContext from "../context/AuthProvider";
import {
  Button,
  Form,
  Modal,
  Row,
  Col,
  Image,
  Container,
  Card,
} from "react-bootstrap";
import { UPDATE_PASSWORD, USER_REDEEM } from "../graphql/Mutations";
import { CgProfile } from "react-icons/cg";
import { FaUserEdit } from "react-icons/fa";

import AWS from "aws-sdk";
import uniqid from "uniqid";
import Resizer from "react-image-file-resizer";
import Subnavbar from "../components/Subnavbar";
const ID = "AKIA6GB4RFKTNZRHNUTV";
const SECRET = "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";
const BUCKET_NAME = "satyambucket";
const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

const User = () => {
  const { setUserId, token, setToken } = useContext(AuthContext);

  const navigate = useNavigate();

  const { data: UserData, refetch: userDataRefech } = useQuery(
    GET_USER_BY_TOKEN,
    {
      variables: {
        token: `${token}`,
      },
      onCompleted: (data) => console.log("@@@@@@@@@@@@@@@@@@@@@@@@@\n", data),
    }
  );

  const [userRedeem] = useMutation(USER_REDEEM);

  const [updateUser, { error: updateUserError }] = useMutation(UPDATE_USER);
  const [updatePass] = useMutation(UPDATE_PASSWORD);

  // // EDIT
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [firstNameEdit, setfirstNameEdit] = useState();
  const [lastNameEdit, setlastNameEdit] = useState();
  const [userImage, setUserImage] = useState("");
  const [contactedit, setcontactedit] = useState();
  const [emailedit, setemailedit] = useState();
  const [oldedit, setoldpassedit] = useState("");
  const [newedit, setnewpassedit] = useState("");
  const [userPoints, setUserPoints] = useState(0);

  useEffect(() => setUserPoints(UserData?.getUserByToken?.points), [UserData]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        "jpg",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  async function userImageUpdater(userImage) {
    try {
      let regex = new RegExp("[^.]+$");
      let extension = userImage.name.match(regex);

      const uniqId = uniqid();

      let uniqueFileName = uniqId + "." + extension[0];

      const image = await resizeFile(userImage);

      const params = {
        Bucket: BUCKET_NAME,
        Key: uniqueFileName,
        Body: image,
      };

      s3.upload(params, function (err, data) {
        if (err) {
          throw err;
        } else {
          updateUser({
            variables: {
              userUpdateInput: {
                token: `${token}`,
                image: `${uniqueFileName}`,
              },
            },
          }).then(() => {
            setUserImage(uniqueFileName);
            userDataRefech();
          });
        }
      });
    } catch (error) {
      console.log("Err", error);
    }
  }

  function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      updateUser({
        variables: {
          userUpdateInput: {
            token: `${token}`,
            firstName: `${firstNameEdit}`,
            lastName: `${lastNameEdit}`,
            email: `${emailedit}`,
            contact: `${contactedit}`,
            image: `${userImage}`,
          },
        },
      })
        .then((data) => {
          swal({
            title: "Success",
            text: "User Profile Updated Successfully",
            icon: "success",
          });
          setShow(false);
        })
        .catch((err) => {
          swal({
            title: "failed",
            text: updateUserError.message,
            icon: "error",
          });
          setShow(false);
        });
    }
  }
  function handleSubmit2(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      updatePass({
        variables: {
          userPasswordUpdateInput: {
            token: `${token}`,
            newPassword: `${newedit}`,
            oldPassword: `${oldedit}`,
          },
        },
      })
        .then((data) => {
          swal({
            title: "Success",
            text: "Password Updated Successfully",
            icon: "success",
          });
          setShow2(false);
        })
        .catch((err) => alert(err));
    }
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  function showEditModal(uData) {
    setfirstNameEdit(uData.getUserByToken?.firstName);
    setlastNameEdit(uData.getUserByToken?.lastName);
    setcontactedit(uData.getUserByToken?.contact);
    setemailedit(uData.getUserByToken?.email);
    setShow(true);
  }

  function handleLogout() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userId");
    sessionStorage.removeItem("userSessionToken");
    setUserId(false);
    setToken(false);
    navigate("/login");
  }

  function RedirectToCoupon() {
    navigate("/user/userCoupons");
  }

  function RedirectToPoints() {
    navigate("/user/userPoints");
  }

  const location = useLocation();

  const [head, sethead] = useState("My Deals, Offers and Coupon Details");

  function handleRedeem() {
    userRedeem({
      variables: {
        token: `${token}`,
      },
    }).then(() => {
      userDataRefech();
    });
  }

  useEffect(() => {
    if (location.pathname === "/user/userCoupons") {
      sethead("My Coupons");
    } else if (location.pathname === "/user/userPoints") {
      sethead("Points");
    }
  }, [location.pathname]);

  return (
    <>
      <Navbar />
      <Subnavbar />
      <div className="user_main mt-5 px-5">
        <div className="row align-items-start ">
          <div className="col-md-3">
            <div className="row bg-white py-4 shadow rounded-4">
              <div className="col">
                <div className="user_card">
                  {UserData?.getUserByToken?.image ? (
                    <Image
                      className="user_pic mx-auto d-block"
                      src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${UserData?.getUserByToken?.image}`}
                    />
                  ) : (
                    <CgProfile className="user_pic mx-auto d-block" />
                  )}

                  <label
                    for="imgEdit"
                    className="text-center"
                    style={{ minWidth: "100%" }}
                  >
                    <FaUserEdit />
                  </label>
                  <input
                    type="file"
                    id="imgEdit"
                    style={{ display: "none" }}
                    onChange={(e) => userImageUpdater(e.target.files[0])}
                  />
                  <h6 className="text-center">
                    {UserData && UserData.getUserByToken?.firstName}{" "}
                    {UserData && UserData.getUserByToken?.lastName}
                  </h6>
                  <Container className="my-2">
                    <p className="text-secondary text-center mb-1">
                      {UserData && UserData.getUserByToken?.email}
                    </p>
                    <p className="text-secondary text-center">
                      {UserData && UserData.getUserByToken?.contact}{" "}
                    </p>
                  </Container>
                  <h6
                    className="text-center"
                    onClick={() => {
                      showEditModal(UserData);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Edit Profile
                  </h6>
                </div>
              </div>
            </div>
            <div className="row mt-3 bg-white py-4 shadow rounded-4">
              <div className="col">
                <div className="user_card px-3">
                  <div className="d-flex align-items-center">
                    <BiSolidCoupon style={{ color: "#19AB55" }} />
                    <h6
                      style={{ cursor: "pointer" }}
                      className="ms-4"
                      onClick={RedirectToCoupon}
                    >
                      My Coupons
                    </h6>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <BiSolidCoupon style={{ color: "#19AB55" }} />
                    <h6
                      style={{ cursor: "pointer" }}
                      className="ms-4"
                      onClick={RedirectToPoints}
                    >
                      My Points
                    </h6>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <BiSolidCoupon style={{ color: "#19AB55" }} />
                    <h6
                      style={{ cursor: "pointer" }}
                      className="ms-4"
                      onClick={() => navigate("/user/Referral")}
                    >
                      Referral
                    </h6>
                  </div>
                  {/* <div className="d-flex align-items-center mt-4">
                    <BiSolidCoupon style={{ color: "#19AB55" }} />
                    <h6 className="ms-4">My Offers</h6>
                  </div> */}
                  {/* <div className="d-flex align-items-center mt-4">
                    <IoIosSettings style={{ color: "#19AB55" }} />
                    <h6 className="ms-4">Profile & Settings</h6>
                  </div> */}
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-primary btn-sm mt-4"
                      onClick={handleLogout}
                    >
                      <IoMdLogOut /> Logout
                    </button>
                    <button
                      className="btn btn-danger ms-3 btn-sm mt-4"
                      onClick={() => {
                        setShow2(true);
                      }}
                    >
                      Update Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 ps-md-5 mt-md-0 mt-5">
            <div className="row ">
              <div className="row">
                <div className="col shadow p-3 rounded-3">
                  {head === "Points" ? (
                    <Row className="my-0">
                      <Col md={6}>
                        <Row>
                          <Col>
                            <h4>My Points</h4>
                          </Col>
                          <Col>
                            <Button onClick={handleRedeem}>Withdrawal</Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="my-0">
                          <Col>
                            <Row className="border rounded-pill">
                              <Col md={8}>Total Points</Col>
                              <Col md={4}>
                                {Number(userPoints?.totalPoints).toFixed(2)}
                              </Col>
                            </Row>
                            <Row className="border rounded-pill mb-0">
                              <Col lg={8}>Login Points</Col>
                              <Col lg={4}>
                                {Number(userPoints?.loginPoints).toFixed(2)}
                              </Col>
                            </Row>
                          </Col>
                          <Col className="mx-2">
                            <Row className="border rounded-pill">
                              <Col lg={8}>Referral Points</Col>

                              <Col lg={4}>
                                {Number(userPoints?.referralPoints).toFixed(2)}
                              </Col>
                            </Row>
                            <Row className="border rounded-pill mb-0">
                              <Col lg={8}>Coupon Points</Col>

                              <Col lg={4}>
                                {Number(userPoints?.couponPoints).toFixed(2)}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <h4>My Coupons</h4>
                  )}
                </div>
              </div>

              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <Footer />
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>User Details Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="box-shadow">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3 brands_form">
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    value={firstNameEdit}
                    onChange={(e) => setfirstNameEdit(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    value={lastNameEdit}
                    onChange={(e) => setlastNameEdit(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Contact</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Contact"
                    value={contactedit}
                    onChange={(e) => setcontactedit(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    value={emailedit}
                    onChange={(e) => setemailedit(e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Button type="submit" className="d-block mx-auto  mt-5">
                Update
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={show2} onHide={() => setShow2(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Password Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="box-shadow">
            <Form noValidate validated={validated} onSubmit={handleSubmit2}>
              <Row className="mb-3 brands_form">
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Old Password</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder="Old Password"
                    value={oldedit}
                    onChange={(e) => setoldpassedit(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="New Password"
                    value={newedit}
                    onChange={(e) => setnewpassedit(e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Button type="submit" className="d-block mx-auto  mt-5">
                Update
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default User;
